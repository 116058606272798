<script setup>
    import { useRoute } from "vue-router";
    import {computed, onMounted, onUnmounted, ref} from "vue";
    import { useContactsLists, storeToRefs } from "@/stores/ContactsLists";

    import Person from "@/components/people/Person.vue";
    import PeopleSelected from "@/components/contacts-lists/PeopleSelected.vue";

    const store = useContactsLists();
    const { contactsLists } = storeToRefs(store);

    const listId = computed (() => {
        return useRoute().params.id;
    });

    const contactsList = computed(() => {
        if (listId.value === null || contactsLists.value === null) {
            return null;
        }
        return contactsLists.value.find(list => list.id === listId.value);
    });

    const isOnline = ref(navigator.onLine);

    const updateOnlineStatus = () => {
        isOnline.value = navigator.onLine;
    };

    onMounted(() => {
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);
    });

    onUnmounted(() => {
        window.removeEventListener('online', updateOnlineStatus);
        window.removeEventListener('offline', updateOnlineStatus);
    });
</script>

<template>
    <div v-if="true">
        <h1>{{ contactsList.name }}</h1>

        <router-link :to="{ name: 'contacts-lists' }" class="text-ns-deep-blue hover:text-ns-dark-blue">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"/>
            </svg>
            <span class="text-ns-deep-blue">Back to contacts lists</span>
        </router-link>

        <div class="flex flex-col space-y-4">
            <div v-for="(value, func) in contactsList.people">
                <h3 class="text-lg lg:text-xl mb-0 mt-12 text-ns-deep-blue font-light">
                    {{ func }} ({{ value.length }})
                </h3>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pt-4">
                    <person
                        :show-checkbox="isOnline"
                        v-for="person in contactsList.people[func]"
                        :key="person.id"
                        :person="person"
                        compact
                    />
                </div>
            </div>
        </div>

        <people-selected />
    </div>
</template>

<style scoped>

</style>
