<script setup>
import {reactive, computed} from 'vue';

const props = defineProps({
    correspondent: {
        type: Object,
        required: true
    }
});

const state = reactive({});

// Compute Google Maps URL based on the coordinates
const googleMapsUrl = computed(() => {
    const correspondent = props.correspondent || {};
    const {
        address_line1,
        address_line2,
        address_line3,
        city,
        postcode,
        country
    } = correspondent;

    // Create an array of the address parts, filtering out any that are not present
    const addressParts = [
        address_line1,
        address_line2,
        address_line3,
        city,
        postcode,
        country
    ].filter(part => part && part.trim() !== '');

    // Join the parts with a comma and encode the result for use in a URL
    const address = addressParts.join(', ');

    if (address) {
        return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    }

    return '#'; // Fallback if no address parts are available
});

</script>
<template>
    <article
        :id="`correspondent_${correspondent.objectID}`"
        class="w-full"
    >
        <h2 v-if="correspondent.name" class="text-ns-deep-blue text-2xl xl:text-3xl">{{correspondent.name}}</h2>

        <div
            class="w-full z-10 relative shadow-lg rounded-lg my-6 bg-gradient-to-br from-ns-deep-purple from-30% to-ns-deep-blue text-white"
        >
            <div class="absolute right-6">
                <div v-if="correspondent.is_lawyer === true"  class="bg-ns-dark-blue flex px-3 items-center py-4 rounded-bl-lg rounded-br-lg"><i class="fa-kit fa-ns-gavel text-xl mr-2"></i> Legal</div>
                <div v-else class="bg-ns-dark-blue flex items-center px-3 py-4 rounded-bl-lg rounded-br-lg"><i class="fa-kit fa-ns-apartment text-xl mr-2"></i> Commercial</div>
            </div>

            <div class="text-white p-6 rounded-lg">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <!-- Left Side -->
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">

                        <div>
                            <h3 class="font-bold text-white text-lg mt-2">Address</h3>
                            <p v-if="correspondent.co_party" class="my-0 break-words text-white">C/O {{ correspondent.co_party }}</p>
                            <p v-if="correspondent.address_line1" class="my-0 break-words text-white">{{ correspondent.address_line1 }}</p>
                            <p v-if="correspondent.address_line2" class="my-0 break-words text-white">{{ correspondent.address_line2 }}</p>
                            <p v-if="correspondent.address_line3" class="my-0 break-words text-white">{{ correspondent.address_line3 }}</p>
                            <p v-if="correspondent.city" class="my-0 text-white">{{ correspondent.city }}</p>
                            <p v-if="correspondent.postcode" class="my-0 text-white">{{ correspondent.postcode }}</p>
                            <p v-if="correspondent.country" class="my-0 text-white">{{ correspondent.country }}</p>
                            <a :href="googleMapsUrl"
                               class="flex items-center mt-2 text-ns-modern-green mr-2 hover:text-ns-modern-green/85"
                               target="_blank" rel="noopener noreferrer">
                                <i class="fa-kit fa-ns-map  mr-2 text-white text-xl"></i> Google Maps
                            </a>
                        </div>

                        <div>
                            <p v-if="correspondent.data && correspondent.data.Email" class="flex flex-col text-white mt-2">
                                <i class="fa-kit fa-ns-mail mb-1 mr-2 text-xl"></i>
                                <a class="text-ns-modern-green break-all hover:text-ns-modern-green/85"  :href="'mailto:' + correspondent.data.Email">{{ correspondent.data.Email }}</a>
                            </p>
                            <p v-if="correspondent.data && correspondent.data.Telephone" class="flex flex-col text-white mt-2">
                                <i class="fa-kit fa-ns-phone mb-1 mr-2 text-xl"></i>
                                <a class="text-ns-modern-green break-all hover:text-ns-modern-green/85"  :href="'tel:' + correspondent.data.Telephone">{{ correspondent.data.Telephone }}</a>
                            </p>
                            <p v-if="correspondent.data && correspondent.data.Website" class="flex flex-col text-white mt-2">
                                <i class="fa-kit fa-globe-alt mb-1 mr-2 text-xl"></i>
                                <a class="text-ns-modern-green break-all hover:text-ns-modern-green/85" target="_blank"  :href="correspondent.data.Website">{{ correspondent.data.Website }}</a>
                            </p>
                            <p v-if="correspondent.data && correspondent.data.Alternative" class="flex flex-col text-white mt-2">
                                <i class="fa-kit fa-ns-web mb-1 mr-2 text-xl"></i>
                                <a class="text-ns-modern-green break-all hover:text-ns-modern-green/85"  :href="correspondent.data.Alternative">{{ correspondent.data.Alternative }}</a>
                            </p>
                        </div>

                    </div>
                    <!-- Right Side -->
                    <div>
                        <div class="flex flex-col">
                            <!--                            <h3 class="font-bold text-lg mt-2 mb-0">Local time</h3>-->
                            <!--                            <p class="mt-1 text-ns-bright-orange"><em>Known issue 1.5.24</em></p>-->
                        </div>

                        <h3 v-if="correspondent.notes" class="font-bold text-lg mt-4 text-white">Notes</h3>
                        <p v-if="correspondent.notes" class="mt-2 text-white">{{ correspondent.notes }}</p>
                    </div>
                </div>

                <!-- Contacts -->
                <div v-if="correspondent.contacts" class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    <div v-for="(contact, index) in correspondent.contacts" :key="index"
                         class="bg-white rounded-lg p-4 text-ns-deep-blue grid grid-cols-1 md:grid-cols-2 gap-4">


                        <div v-if="contact.full_name" class="text-lg font-bold">
                            {{ contact.full_name }}
                        </div>

                        <div v-if="contact.phone.default && contact.phone.default.length > 0" class="flex flex-col">
                            <strong>Tel (work):</strong>
                            <span v-for="(phone, phoneIndex) in contact.phone.default" :key="phoneIndex">
                                <a class="hover:text-ns-dark-blue break-all" :href="'tel:' + phone">{{ phone }}</a>
                            </span>
                        </div>

                        <div v-if="contact.email && contact.email.length > 0" class="flex flex-col">
                            <strong>Email</strong>
                            <span v-for="(email, emailIndex) in contact.email" :key="emailIndex">
                                <a class="hover:text-ns-dark-blue break-all" :href="'mailto:' + email">{{ email }}</a>
                            </span>
                        </div>

                        <div v-if="contact.phone.personal && contact.phone.personal.length > 0" class="flex flex-col">
                            <strong>Tel (mobile):</strong>
                            <span v-for="(phone, phoneIndex) in contact.phone.personal" :key="phoneIndex">
                                <a class="hover:text-ns-dark-blue break-all" :href="'tel:' + phone">{{ phone }}</a>
                            </span>
                        </div>

                        <div v-if="contact.phone.out_of_hours && contact.phone.out_of_hours.length > 0" class="flex flex-col">
                            <strong>Out of Hours:</strong>
                            <span v-for="(phone, phoneIndex) in contact.phone.out_of_hours" :key="phoneIndex">
                                <a class="hover:text-ns-dark-blue break-all" :href="'tel:' + phone">{{ phone }}</a>
                            </span>
                        </div>

                    </div>
                </div>

<!--                <div class="text-left mt-4">-->
<!--                    Need to update your details?-->
<!--                    <button @click="handleOpenModal"-->
<!--                            class="text-ns-modern-green px-2 py-1 font-bold underline mr-2 hover:cursor-pointer hover:text-ns-modern-green/85">Request-->
<!--                        update</button>-->
<!--                </div>-->
            </div>
        </div>
    </article>
</template>
<style scoped>

</style>
