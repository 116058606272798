<script setup>
import { reactive, computed, onMounted } from 'vue';
import SingleCorrespondent from './SingleCorrespondent.vue';
import { searchIndexedDB } from '../idb-utils';

const state = reactive({
    searchInput: '',
    tags: [],
    fetchedItems: [],
    nbHits: 0,
    isLawyer: null,
    showSuggestions: false,
    resultsVisible: false,
    accordionStates: {},
});

function toggleAccordion(portName) {
    state.accordionStates[portName] = !state.accordionStates[portName];
}

function isAccordionOpen(portName) {
    return !!state.accordionStates[portName];
}

// Function to load results from IndexedDB based on search term
async function loadResultsFromIndexedDB(searchQuery, storeName) {
    try {
        const results = await searchIndexedDB(storeName, searchQuery);
        console.log('Offline search results:', results);
        return results;
    } catch (error) {
        console.error('Error loading results from IndexedDB:', error);
        throw error;
    }
}

async function performOfflineSearch(searchQuery, storeName = 'correspondents') {
    console.log('Triggering search with searchQuery:', searchQuery);

    if (!searchQuery) {
        searchQuery = state.searchInput.trim() || state.tags.join(' ') || '';
    }

    if (searchQuery) {
        state.resultsVisible = true;

        console.log('Offline Search');
        try {
            state.tags = [searchQuery];
            state.fetchedItems = await loadResultsFromIndexedDB(searchQuery, storeName); // Load results from IndexedDB
            state.nbHits = state.fetchedItems.length;
        } catch (error) {
            console.error('Error during offline search from IndexedDB:', error);
            state.fetchedItems = []; // Reset Results array
            state.resultsVisible = false; // Hide results
            state.nbHits = 0; // Reset hits on error
        }

        state.showSuggestions = false;
    } else {
        state.resultsVisible = false;
    }
}

function onInput(value) {
    state.searchInput = value;
    state.showSuggestions = value.trim().length >= 3;
}

function handleSubmit() {
    performOfflineSearch(state.searchInput.trim());
}

function searchAll() {
    state.searchInput = '';
    state.tags = ['all'];
    state.isLawyer = null;
    performOfflineSearch('all');
}

function clearSearch() {
    state.searchInput = '';
    state.tags = [];
    state.fetchedItems = [];
    state.showSuggestions = false;
    state.isLawyer = null;
    state.resultsVisible = false;
}

function removeTag(tag) {
    state.tags = state.tags.filter(t => t !== tag);
    if (state.tags.length === 0) {
        state.fetchedItems = [];
        state.resultsVisible = false;
        state.isLawyer = null;
    }
    performOfflineSearch(state.tags.join(' '));
}

function filterLegal() {
    state.isLawyer = state.isLawyer === true ? null : true;
    performOfflineSearch(state.searchInput.trim());
}

function filterCommercial() {
    state.isLawyer = state.isLawyer === false ? null : false;
    performOfflineSearch(state.searchInput.trim());
}

const groupedResults = computed(() => {
    const grouped = {};
    state.fetchedItems.forEach(item => {
        const country = item.port?.country || 'Unknown';
        const portName = item.port?.name || 'Unknown Port';
        if (!grouped[country]) grouped[country] = {};
        if (!grouped[country][portName]) grouped[country][portName] = { items: [] };
        grouped[country][portName].items.push(item);
    });
    return grouped;
});
</script>

<template>
    <div>
        <div class="offline-banner bg-yellow-100 text-yellow-800 p-4 rounded mb-4">
            <p>
                Please note, as you are currently offline, your results may only reflect information from the last day you were connected. Once you are back online, the app will automatically and seamlessly resync so you have the very latest information.
            </p>
            <p>
                If you're unsure about the most up-to-date version, see our website's correspondents search at <a href="https://north-standard.com/correspondents-search" class="underline underline-offset-4">https://north-standard.com/correspondents-search</a>.
            </p>
        </div>
        <div class="autocomplete-container w-full rounded-lg p-4"
             style="box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);">
            <form @submit.prevent="handleSubmit">
                <div class="relative flex flex-col w-full md:flex-row space-y-2 md:space-y-0 md:space-x-2 items-center">
                    <div
                        class="relative flex w-full flex-1 items-center bg-ns-gray rounded-lg focus:outline-none focus:ring-0">
                        <i class="fas fa-search px-4 text-gray-400"></i>
                        <input
                            class="flex-1 form-input drop-shadow-none font-semibold bg-transparent py-3 rounded-lg focus:outline-none focus:ring-0"
                            placeholder="Search by Country, Port or Company"
                            v-model="state.searchInput"
                            @input="onInput($event.target.value)"
                            type="search"/>
                    </div>
                    <div class="flex flex-col md:space-x-2 w-full md:flex-row md:w-auto">
                        <button type="submit"
                                class="w-full mt-6 md:w-auto md:my-0 inline-block button button--green focus:outline-none">
                            Search
                        </button>
                        <button type="button" @click="searchAll"
                                class="w-full font-semibold my-6 md:w-auto md:my-0 px-4 py-2 text-ns-deep-blue border border-ns-deep-blue rounded-lg hover:bg-ns-gray focus:outline-none">
                            View all correspondents
                        </button>
                        <button type="button" @click="clearSearch"
                                class="w-auto font-semibold uppercase md:w-auto px-4 py-2 text-ns-deep-blue rounded-lg hover:text-red-600 focus:outline-none ">
                            Clear
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div v-if="state.resultsVisible">
            <p class="font-heading">Your search returned <span class="font-semibold">{{ state.nbHits }} results</span></p>
        </div>

<!--        <div v-if="state.resultsVisible" class="toggle-container flex flex-col items-end mb-8">-->
<!--            <div class="w-full items-center justify-between md:w-1/3 2xl:w-1/4 flex gap-2">-->
<!--                <span class="text-ns-deep-blue">Filter by:</span>-->
<!--                <div class="flex gap-2">-->
<!--                    <button @click="filterCommercial"-->
<!--                            :class="{'bg-ns-dark-blue rounded-lg py-1 px-4  text-white inline-flex items-center': state.isLawyer === false}"-->
<!--                            class="w-auto text-ns-deep-blue px-4 py-1 border border-ns-deep-blue rounded-lg hover:bg-ns-gray focus:outline-none ">-->
<!--                        <i class="fa-kit fa-ns-apartment text-ns-deep-blue text-xl"></i>-->
<!--                        <span>Commercial</span>-->
<!--                    </button>-->
<!--                    <button @click="filterLegal"-->
<!--                            :class="{'bg-ns-dark-blue hover:bg-ns-dark-blue!important rounded-lg py-1 px-4 text-white inline-flex items-center': state.isLawyer === true}"-->
<!--                            class="w-auto text-ns-deep-blue px-4 py-1 border border-ns-deep-blue rounded-lg hover:bg-ns-gray focus:outline-none">-->
<!--                        <i class="fa-kit fa-ns-gavel text-ns-deep-blue text-xl"></i>-->
<!--                        <span>Legal</span>-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

        <div class="flex flex-col md:space-x-8 md:flex-row">
            <section class="correspondents-search w-full md:w-2/3 xl:w-[70%] 2xl:w-3/4">
                <div class="grid grid-cols-1 md:grid-cols-1 gap-6 w-full mb-0 md:mb-20">
                    <div v-for="(ports, country) in groupedResults" :key="country">
                        <span class="font-bold flex uppercase mb-2">{{ country }}</span>
                        <div v-for="(portData, portName) in ports" :key="portName" class="mb-4 p-4 rounded-lg"
                             style="box-shadow: rgba(0, 0, 0, 0.1) 2px 10px 15px -3px, rgba(0, 0, 0, 0.05) 2px 4px 6px -2px;">
                            <button
                                type="button"
                                :class="['flex','items-center','justify-between','text-xl','bg-ns-gray','text-ns-deep-blue','rounded-tl-lg','rounded-tr-lg','rounded-bl-lg','rounded-br-lg','w-full','font-heading','text-left','appearance-none','transition-all','duration-300','ease-in-out','py-4','px-4','hover:bg-ns-gray/80',
                  {'rounded-bl-none rounded-br-none mb-0': isAccordionOpen(portName)}]"
                                @click="toggleAccordion(portName)">
                  <span>
                    <i class="fa-kit fa-ns-anchor text-2xl mr-2"></i>
                    <strong>{{ portName }}</strong>
                  </span>
                                <i :class="{'scale-y-100': !isAccordionOpen(portName),
                            '-scale-y-100': isAccordionOpen(portName),
                        }"
                                   class="fa-sharp fa-chevron-down transform transition-transform duration-300"/>
                            </button>
                            <Transition
                                v-on:before-enter="beforeEnter"
                                v-on:enter="enter"
                                v-on:before-leave="beforeLeave"
                                v-on:leave="leave">
                                <div v-if="isAccordionOpen(portName)"
                                     class="accordion-content overflow-hidden transition-all duration-300 ease-in-out">
                                    <div
                                        class="bg-ns-gray pb-4 pt-2 px-4 rounded-br-lg rounded-bl-lg text-ns-deep-blue">
                                        <hr class="mx-4 mb-4 border-white">
                                        <SingleCorrespondent
                                            v-for="item in portData.items"
                                            :key="item.objectID"
                                            :correspondent="item"/>
                                    </div>
                                </div>
                            </Transition>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
