<script setup>
import { reactive } from 'vue';

const props = defineProps({
    vessel: {
        type: Object,
        required: true
    }
});

const state = reactive({
    expanded: false
});

const beforeEnter = (el) => {
    el.style.opacity = '0'
    el.style.height = '0';
};

const enter = (el) => {
    el.style.opacity = '1'
    el.style.height = `${el.scrollHeight}px`;
};

const beforeLeave = (el) => {
    el.style.opacity = '1'
    el.style.height = `${el.scrollHeight}px`;
};

const leave = (el) => {
    el.style.opacity = '0'
    el.style.height = '0';
};
</script>
<template>
    <article
        :id="`vessel_${vessel.objectID}`"
        class="w-full"
    >
        <button
            :aria-label="`Click to ${state.expanded ? 'hide the' : 'view more details about the'} '${vessel.name}' vessel`"
            :class="state.expanded ? 'rounded-t-lg' : 'rounded-lg'"
            type="button"
            class="flex items-center justify-between text-xl bg-ns-gray w-full font-heading text-left appearance-none py-4 px-4 hover:bg-ns-gray/80"
            @click="state.expanded = !state.expanded"
        >
            <strong>{{ vessel.name }} <span v-if="vessel.ship_imo && vessel.ship_imo !== '0'" class="text-sm ml-2 font-heading">&nbsp;{{ vessel.ship_imo }}</span></strong>
            <i
                :class="{
                    'scale-y-100': !state.expanded,
                    '-scale-y-100': state.expanded,
                }"
                class="fa-sharp fa-chevron-down transform transition-transform duration-300"
            />
        </button>
        <Transition
            v-on:before-enter="beforeEnter"
            v-on:enter="enter"
            v-on:before-leave="beforeLeave"
            v-on:leave="leave"
        >
            <div
                v-if="state.expanded"
                class="transition-all duration-300 ease-in-out"
            >
                <div class="pb-3">
                    <div class="grid grid-cols-1 lg:grid-cols-2 bg-ns-gray rounded-b-lg gap-4 px-4 py-4">
                        <div
                            :class="{
                                'col-span-full': !vessel?.bluecards?.length
                            }"
                        >
                            <table class="min-w-full drop-shadow-none table-auto bg-transparent">
                                <tbody>
                                <tr
                                    v-if="vessel.vessel_group_value"
                                    class="bg-transparent align-top"
                                >
                                    <td class="text-ns-deep-blue px-0 py-2 font-bold" style="width: 80px;">
                                        {{ vessel.vessel_group_label }}
                                    </td>
                                    <td class="px-2 py-2">
                                        <span class="font-normal text-wrap">{{ vessel.vessel_group_value }}</span>
                                    </td>
                                </tr>
                                <tr
                                    v-if="vessel.ship_imo && vessel.ship_imo !== '0'"
                                    class="bg-transparent align-top"
                                >
                                    <td class="text-ns-deep-blue px-0 py-2 font-bold">
                                        IMO:
                                    </td>
                                    <td class="font-normal px-2 py-2">
                                        <span class="font-normal">{{ vessel.ship_imo }}</span>
                                    </td>
                                </tr>
                                <tr
                                    v-if="vessel.gross_tonnage && vessel.gross_tonnage !== '0'"
                                    class="bg-transparent align-top"
                                >
                                    <td class="text-ns-deep-blue px-0 py-2 font-bold">
                                        GT:
                                    </td>
                                    <td class="px-2 py-2">
                                        <span class="font-normal">{{ vessel.gross_tonnage }}</span>
                                    </td>
                                </tr>
                                <tr
                                    v-if="vessel.flag_code"
                                    class="bg-transparent align-top"
                                >
                                    <td class="text-ns-deep-blue px-0 py-2 font-bold">
                                        Flag:
                                    </td>
                                    <td class="px-2 py-2">
                                        <span class="font-normal">{{ vessel.flag_code }}</span>
                                    </td>
                                </tr>
                                <tr
                                    v-if="vessel.built_year && vessel.built_year !== '0'"
                                    class="bg-transparent align-top"
                                >
                                    <td class="text-ns-deep-blue px-0 py-2 font-bold">
                                        Year:
                                    </td>
                                    <td class="px-2 py-2">
                                        <span class="font-normal">{{ vessel.built_year }}</span>
                                    </td>
                                </tr>
                                <tr
                                    v-if="vessel.type_description"
                                    class="bg-transparent align-top"
                                >
                                    <td class="text-ns-deep-blue px-0 py-2 font-bold">
                                        Ship Type:
                                    </td>
                                    <td class="px-2 py-2">
                                        <span class="font-normal">{{ vessel.type_description }}</span>
                                    </td>
                                </tr>
                                <template v-if="vessel?.insurer_multi && Object.values(vessel.insurer_multi).length > 0">
                                    <tr
                                        v-for="[year, insurer] in Object.entries(vessel?.insurer_multi)"
                                        :key="year"
                                        class="bg-transparent align-top"
                                    >
                                        <td class="text-ns-deep-blue px-0 py-2 font-bold">
                                            Insurer<wbr><span class="whitespace-nowrap">{{ year }}/{{ (Number.parseInt(year) + 1) }}:</span>
                                        </td>
                                        <td class="px-2 py-2">
                                            <span class="font-normal">{{ insurer }}</span>
                                        </td>
                                    </tr>
                                </template>
                                <tr
                                    v-else-if="vessel.insurer_value"
                                    class="bg-transparent align-top"
                                >
                                    <td class="text-ns-deep-blue px-0 py-2 font-bold">
                                        Insurer:
                                    </td>
                                    <td class="px-2 py-2">
                                        <span class="font-normal">{{ vessel.insurer_value }}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="vessel?.bluecards?.length > 0">
                            <table class="min-w-full drop-shadow-none table-auto bg-transparent">
                                <tbody>
                                <tr v-for="(card, index) in vessel.bluecards" :key="index" class="bg-transparent align-top">
                                    <!-- Fixed width for the first <td> -->
                                    <td class="text-ns-deep-blue px-0 py-2 font-bold" style="width: 25px;">
                                        <i class="fa-solid fa-check mr-1"></i>
                                    </td>
                                    <!-- Expand the second <td> to fill the remaining space -->
                                    <td class="text-ns-deep-blue px-0 py-2 font-bold">
                                        {{ card.label }}:
                                        <span class="font-normal">From {{ card.date_start.iso }} to {{ card.date_end.iso }} </span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-span-full w-full flex-col flex bg-white text-ns-deep-blue rounded-lg font-bold px-3 py-2">
                            Last updated: <span class="font-normal mt-2">{{ vessel.vessel_last_updated_date }} - {{ vessel.vessel_last_updated_time }} GMT</span>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    </article>
</template>
<style scoped>
table {
    border: none;
    max-width: 100%; /* or a specific value like 600px */
    width: 100%; /* Make the table responsive within its container */
    table-layout: fixed; /* Fix table layout to ensure consistent cell sizes */
}
table tbody {
    border: none;
}
table tr {
    border: none;
}
table td {
    word-wrap: break-word; /* Wrap words to avoid overflow */
    overflow-wrap: break-word; /* Support for older browsers */
    border: none;
}
</style>
