<script setup>
import { ref, computed, watch } from 'vue';
import SingleVessel from './SingleVessel.vue';

const props = defineProps({
    results: Array,
    searchInput: String,
    tags: Array,
    resultsVisible: Boolean
});

const emit = defineEmits(['trigger-search', 'clear-search', 'remove-tag']);

const searchInput = ref(props.searchInput);
const tags = ref(props.tags);
const resultsVisible = ref(props.resultsVisible);

const resultCount = computed(() => props.results.length);
const sortedResults = computed(() => props.results.slice().sort((a, b) => a.name.localeCompare(b.name)));

// Watchers to sync props with internal state
watch(() => props.searchInput, (newVal) => {
    searchInput.value = newVal;
});

watch(() => props.tags, (newVal) => {
    tags.value = newVal;
});

watch(() => props.resultsVisible, (newVal) => {
    resultsVisible.value = newVal;
});

// Emit events to parent component
function triggerSearch() {
    console.log('Emitting trigger-search with searchInput:', searchInput.value);
    emit('trigger-search', searchInput.value);
}

function clearSearch() {
    console.log('Emitting clear-search');
    searchInput.value = '';  // Clear the search input
    emit('clear-search');
}
function removeTag(tag) {
    console.log('Emitting remove-tag with tag:', tag);
    searchInput.value = '';  // Clear the search input
    emit('remove-tag', tag);
}

function onInput(event) {
    searchInput.value = event.target.value;
    console.log('Input event: ', searchInput.value);
    // Optionally you can emit an event for real-time input handling if needed
    // emit('input', searchInput.value);
}
</script>

<template>
    <div>
        <div class="offline-banner bg-yellow-100 text-yellow-800 p-4 rounded mb-4">
            <p>Please note, as you are currently offline, your results reflect information from when you were last connected. Once you are back online, the app will automatically and seamlessly re-sync so you have the very latest information.</p>
            <p>If you're unsure about the most up-to-date version, see our website's vessel search at <a class="underline underline-offset-4" href="https://north-standard.com/vessel-search">https://north-standard.com/vessel-search</a>.</p>
        </div>
        <div class="autocomplete-container w-full rounded-lg p-4" style="box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);">
            <form @submit.prevent="triggerSearch">
                <div class="relative flex flex-col w-full md:flex-row space-y-2 md:space-y-0 md:space-x-2 items-center">
                    <div class="relative flex flex-1 items-center w-full xl:w-auto bg-ns-gray rounded-lg focus:outline-none focus:ring-0">
                        <i class="fas fa-search px-4 text-gray-400"></i>
                        <input
                            v-model="searchInput"
                            type="search"
                            placeholder="Search by vessel name or IMO no"
                            class="flex-1 form-input drop-shadow-none font-semibold bg-transparent py-3 rounded-lg focus:outline-none"
                            @input="onInput"
                        />
                    </div>
                    <div class="flex md:space-x-2 w-full flex-col md:flex-row md:w-auto">
                        <button
                            type="submit"
                            class="w-full md:w-auto inline-block button button--green focus:outline-none"
                        >
                            Search
                        </button>
                        <button
                            type="button"
                            class="w-full font-semibold md:w-auto px-4 py-2 text-ns-deep-blue rounded-lg hover:text-red-600 focus:outline-none"
                            @click="clearSearch"
                        >
                            CLEAR
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div v-if="resultsVisible" class="flex flex-col md:flex-row gap-4 mt-8 w-full items-start md:items-center">
            <div class="md:flex-none">
                <h4 class="my-2 text-left md:text-right">Search results for</h4>
            </div>
            <div class="flex-grow flex flex-wrap items-start md:items-center">
                <span v-for="tag in tags" :key="tag" class="bg-ns-dark-blue rounded-lg py-2 px-4 mr-2 mb-2 text-white inline-flex items-center">
                    <span>{{ tag }}</span>
                    <button :aria-label="`Remove the '${tag}' search refinement`" type="button" class="ml-2 hover:text-ns-dark-green" @click="() => removeTag(tag)">
                        <i class="fa-regular fa-xmark"></i>
                    </button>
                </span>
            </div>
        </div>
        <div class="result-count text-gray-700 mb-4">
            <p>Your search returned <span class="font-semibold">{{ resultCount }} results</span></p>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 w-full mb-20">
            <SingleVessel v-for="item in sortedResults" :key="item.objectID" :vessel="item" />
        </div>
    </div>
</template>
<style scoped>
.offline-banner {
    border: 1px solid #ecc94b;
}
.result-count {
    font-weight: bold;
}
</style>
