<script setup>
import { ref, onMounted } from 'vue';
import moment from 'moment-timezone';

// Initialise the time info with default values
const defaultTimeInfo = { time: 'loading...', icon: 'fa-clock' };
const ukTime = ref(defaultTimeInfo);
const singaporeTime = ref(defaultTimeInfo);

// Function to get the icon based on the hour
const getIcon = (hour) => {
    return (hour >= 6 && hour < 18) ? 'fa-sun' : 'fa-moon';
};

// Function to get the time and icon information for a specific timezone
const getTimeInfo = (timezone) => {
    const now = moment().tz(timezone);
    const hour = now.hour();
    const icon = getIcon(hour);
    return {
        time: now.format('HH:mm'),
        icon: icon
    };
};

// Function to update the time information for both UK and Singapore
const updateTimes = () => {
    const ukInfo = getTimeInfo('Europe/London');
    const sgInfo = getTimeInfo('Asia/Singapore');
    ukTime.value = ukInfo;
    singaporeTime.value = sgInfo;
};

// Run updateTimes on component mount and set an interval to update it every minute
onMounted(() => {
    updateTimes();
    setInterval(updateTimes, 60000);
});
</script>

<template>
    <div class="block-inner lg:px-8 relative transition duration-300 emergency-contact-inner">
        <div class="grid gap-8 md:gap-10 lg:gap-12 py-8 md:py-10 lg:py-12">
            <div class="grid md:grid-cols-2 gap-4">
                <div class="flex flex-col justify-center">
                    <h1 class="my-0 text-2xl">Emergency contact</h1>
                </div>
                <div class="flex flex-col justify-center">
                    <p class="my-2">If you need immediate or urgent help, or advice out of office hours, please call the appropriate number below.</p>
                    <p class="my-2">To get in touch with any of your contacts in our regional offices, please use the <a class="text-ns-bright-orange hover:text-ns-dark-orange" href="https://north-standard.com/offices" target="_blank" >Office Directory <i class="fa-regular fa-arrow-up-right-from-square"></i></a> .</p>
                    <div>
                        <a href="/" class="close-button absolute top-0 -right-1 items-center justify-center text-white hover:text-ns-modern-green py-2 px-3 transition duration-300">
                            <i class="fa-solid fa-xmark text-2xl"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="grid lg:grid-cols-2 gap-8 md:gap-10 lg:gap-12">
                <div class="bg-ns-gray text-ns-deep-blue py-4 px-4 rounded-lg flex flex-col items-center gap-2">
                    <h3 class="my-0 text-ns-deep-blue">UK</h3>
                    <a :href="`tel:+443331889999`" class="transition duration-300 hover:text-ns-dark-orange flex items-center gap-2">
                        <i class="fa-light fa-phone text-xl"></i>
                        <span>Tel:</span>
                        <span>+44 333 1889 999</span>
                    </a>
                    <div v-if="ukTime.value && ukTime.value.icon && ukTime.value.time">
                        <div class="flex items-center gap-2">
                            <i :class="`fa ${ukTime.value.icon} text-xl`"></i>
                            <span>{{ ukTime.value.time }}</span>
                        </div>
                    </div>
                    <div>
                        <a :href="`tel:+443331889999`" class="button bg-ns-dark-orange border border-ns-dark-orange flex items-center gap-3 hover:border-ns-dark-orange hover:bg-transparent hover:text-ns-dark-orange text-white">
                            <i class="fa-solid fa-phone"></i>
                            <span>Call</span>
                        </a>
                    </div>
                </div>
                <div class="bg-ns-gray text-ns-deep-blue py-4 md:py-8 lg:py-8 px-4 md:px-6 lg:px-8 rounded-lg flex flex-col items-center gap-5">
                    <h3 class="my-0 text-ns-deep-blue">Singapore</h3>
                    <a :href="`tel:+6567513461`" class="transition duration-300 hover:text-ns-dark-orange flex items-center gap-2">
                        <i class="fa-light fa-phone text-xl"></i>
                        <span>Tel:</span>
                        <span>+65 6751 3461</span>
                    </a>
                    <div v-if="singaporeTime.value && singaporeTime.value.icon && singaporeTime.value.time">
                        <div class="flex items-center gap-2">
                            <i :class="`fa ${singaporeTime.value.icon} text-xl`"></i>
                            <span>{{ singaporeTime.value.time }}</span>
                        </div>
                    </div>
                    <div>
                        <a :href="`tel:+6567513461`" class="button bg-ns-dark-orange border border-ns-dark-orange flex items-center gap-3 hover:border-ns-dark-orange hover:bg-transparent hover:text-ns-dark-orange text-white">
                            <i class="fa-solid fa-phone"></i>
                            <span>Call</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="rich-text hidden">
                <p>If you are unable to reach your usual contact <a title="View our people" class="text-ns-modern-green hover:text-ns-modern-green/85" href="/people/search">[View Contacts]</a> out of hours, please call the UK or Singapore numbers listed above.</p>
            </div>
        </div>
    </div>
</template>

<style>
</style>
