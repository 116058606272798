import { createApp, defineAsyncComponent } from 'vue';
import { router } from './router.js';
import { createPinia } from 'pinia';
import { i18nVue } from 'laravel-vue-i18n';
import { defineAppComponents } from './components.js';
import InstantSearch from 'vue-instantsearch/vue3/es';
import axios from 'axios';
import './registerServiceWorker';

// Setting up global axios defaults
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.withCredentials = true;
window.axios.defaults.withXSRFToken = true;

// Algolia environment variables
window.ALGOLIA_APP_ID = import.meta.env.VITE_ALGOLIA_APP_ID;
window.ALGOLIA_PUBLIC = import.meta.env.VITE_ALGOLIA_SEARCH;
window.ALGOLIA_USE_DEV_INDEXES = import.meta.env.VITE_ALGOLIA_USE_DEV_INDEXES === 'true';

const pinia = createPinia();
const app = createApp(defineAsyncComponent(() => import('./App.vue')));

// Register global app components
defineAppComponents(app);

// Theme getter (for use in components)
    // Usage:
    // import { inject } from 'vue';
    // import { useRoute } from 'vue-router';
    // const darkMode = inject('$darkMode');
    // if (darkMode(useRoute()) {
    //     // Do something
    // }
const darkMode = (route) => {
    return route.meta.theme === 'dark';
};
app.provide('$darkMode', darkMode);

// Registering plugins
app.use(pinia);
app.use(router);
app.use(InstantSearch);
app.use(i18nVue);

// Mounting the app to the #app div
app.mount('#app');
