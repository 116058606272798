<script setup>

</script>

<template>
    <div>
        <h1>My Account</h1>
        <!-- Add account functionality here -->
    </div>
</template>
