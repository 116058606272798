import { defineStore, storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue';

const useAuthStore = defineStore('authStore', () => {
    /* State */

    const
        user = ref(null)
    ;

    /* Actions */
    const createMainSiteAccount = () => {
        // Get CSRF first
        return getCsrf().then(() => {
            return axios({
                method: 'POST',
                url: '/api/auth/create-main-site-account'
            });
        });
    }

    const getCsrf = () => {
        return axios.get('/sanctum/csrf-cookie');
    }

    /* Getters */

    const userHasMainSiteAccount = computed(() => {
        return !!user.value?.ns_site_id;
    });

    const userAccessToken = computed(() => {
        return user.value?.access_token;
    });

    /* Watchers */

    watch(user, (newUser) => {
        if (newUser) {
            window.localStorage.setItem('user', JSON.stringify(newUser));
            axios.defaults.headers.common['Authorization'] = `Bearer ${newUser.access_token}`;
        }
    });

    return {
        /* State */
        user,

        /* Actions */
        createMainSiteAccount,

        /* Getters */
        userHasMainSiteAccount,
        userAccessToken,
    }
});

export { useAuthStore, storeToRefs };
