<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import { useCorrespondentList } from '@/stores/CorrespondentList';
import { storeToRefs } from 'pinia';

// Access store and extract reactive properties
const store = useCorrespondentList();
const { savedPorts, correspondentList } = storeToRefs(store);

// Directly use the function from the store
const getCorrespondentList = store.getCorrespondentList;

const emit = defineEmits(['removePort']);

// State variables for button status
const isDownloading = ref(false); // Use a ref directly for boolean states
const downloadButtonText = ref('Download PDF');
const downloadButtonIcon = ref('fa-circle-arrow-down');


//Temp hides
const canDownload = ref(false);
const canEmail = ref(false);

// State variable for loading ports
const isLoadingPorts = ref(true);

const emailAddress = ref('');
const isEmailing = ref(false);
const emailButtonText = ref('Email PDF');
const emailButtonIcon = ref('fa-envelope');

// Helper function to set download button states
function setDownloadButtonState(downloading, text, icon) {
    isDownloading.value = downloading;
    downloadButtonText.value = text;
    downloadButtonIcon.value = icon;
}

// Extract all port names into an array
function getPortNames() {
    if (!savedPorts.value) {
        console.error('savedPorts is undefined or null');
        return [];
    }

    // Log the state of savedPorts
    console.log('savedPorts:', savedPorts.value);

    return Object.values(savedPorts.value).flat().map(port => port.name);
}

async function downloadPortsData() {
    setDownloadButtonState(true, 'Please wait a minute...', 'fa-spinner fa-spin');

    try {
        const portNames = getPortNames();
        const response = await axios.post('/ports/port-pdf', { portNames }, {
            responseType: 'blob' // Expect a binary file response
        });

        // Create a download URL for the blob
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const downloadUrl = window.URL.createObjectURL(blob);

        // Create an anchor element and click it to trigger the download
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'my_correspondents_download.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setDownloadButtonState(false, 'Success - PDF downloading', 'fa-check');
    } catch (error) {
        alert('Failed to download port data');
        setDownloadButtonState(false, 'Download PDF', 'fa-circle-arrow-up transform rotate-180');
    }

    // Reset the button state after a delay
    setTimeout(() => {
        setDownloadButtonState(false, 'Download PDF', 'fa-circle-arrow-up transform rotate-180');
    }, 3000); // Adjust the delay as needed
}

async function emailPortsData() {
    if (!emailAddress.value) {
        alert('Please enter a valid email address.');
        return;
    }

    // Update the button to show the loading state
    isEmailing.value = true;
    emailButtonText.value = 'Sending...';
    emailButtonIcon.value = 'fa-spinner fa-spin';

    try {
        const portNames = getPortNames();
        await axios.post('/ports/port-email', { portNames, email: emailAddress.value });

        emailButtonText.value = 'Success - Email Sent!';
        emailButtonIcon.value = 'fa-check';
    } catch (error) {
        alert('Failed to email port data');
        emailButtonText.value = 'Email PDF';
        emailButtonIcon.value = 'fa-envelope';
    } finally {
        isEmailing.value = false;
        // Reset the button state after a delay
        setTimeout(() => {
            emailButtonText.value = 'Email PDF';
            emailButtonIcon.value = 'fa-envelope';
        }, 3000);
    }
}

// Construct a URL for the port or country search based on query parameters
function generateSearchUrl(baseType, name) {
    const searchParams = new URLSearchParams({ [baseType]: name });
    return `correspondents-search?${searchParams.toString()}`;
}

function removePort(portName, country) {
    emit('removePort', { portName, country });
}


// Return a sorted array of countries
const sortedCountries = computed(() => {
    if (!savedPorts.value) {
        console.error('savedPorts is undefined or null in sortedCountries');
        return [];
    }

    return Object.keys(savedPorts.value).sort();
});

// Simulate loading delay for ports
onMounted(async () => {
    console.log('Component mounted. Fetching correspondent list...');
    try {
        await getCorrespondentList();
        console.log('Correspondent list fetched successfully.');
        isLoadingPorts.value = false;
        if (!savedPorts.value) {
            console.error('savedPorts is still undefined or null after getCorrespondentList');
        } else {
            console.log('savedPorts after getCorrespondentList:', savedPorts.value);
        }
    } catch (error) {
        console.error('Failed to fetch saved ports:', error);
    }
});


// Watch for changes in savedPorts to trigger reactivity
watch(savedPorts, (newValue) => {
    console.log('savedPorts updated:', newValue);
});


</script>

<template>
    <div>
        <h5 class="mt-0 mb-6">My Correspondents</h5>
        <div v-if="isLoadingPorts" class="text-center">
            <i class="fa fa-spinner fa-spin text-4xl text-gray-500"></i>
            <p>Loading ports, please wait...</p>
        </div>
        <div v-else class="max-h-80 mb-6 overflow-y-auto correspondent-scrollbar">
            <div v-for="country in sortedCountries" :key="country" class="mb-4">
                <p class="text-ns-deep-blue text-md font-semibold">
                    <img v-if="savedPorts[country][0]?.flag" :src="savedPorts[country][0]?.flag" alt="Flag" class="w-10 inline mr-2" />
                    <i v-else class="fa-kit fa-north-standard-icon text-ns-deep-blue inline mr-2"></i>

                    <a :href="generateSearchUrl('search', country)" class="text-ns-deep-blue hover:underline">
                        {{ country }}
                    </a>
                </p>
                <ul class="ml-5">
                    <li v-for="port in savedPorts[country]" :key="port.name"
                        class="list-item-with-line w-full flex flex-row items-center justify-between">
                    <span class="">
                        <i class="fa-kit fa-ns-anchor text-2xl leading-none"></i>
                        <a :href="generateSearchUrl('search', port.name)" class="text-ns-deep-blue hover:underline">
                            {{ port.name }}
                        </a>
                    </span>
                        <button class="text-ns-dark-orange text-xl transition-all duration-300 ease-in-out hover:text-ns-bright-orange" @click="removePort(port.name, country)">
                            <i class="fa-solid fa-plus mr-2 -rotate-45"></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="flex w-full flex-col">
            <button v-if="canDownload !== false" @click="downloadPortsData" :disabled="isDownloading" class="w-full my-4 text-center button button--green focus:outline-none transition-all duration-300 ease-in-out mt-0">
                <i :class="`fa-solid ${downloadButtonIcon}`"></i>
                {{ downloadButtonText }}
            </button>
            <div v-if="canEmail !== false">
                <input v-model="emailAddress" type="email" placeholder="Enter your email" class="w-full my-4 px-4 py-2 border rounded">
                <button @click="emailPortsData" :disabled="isEmailing" class="w-full my-4 text-center button button--blue">
                    <i :class="`fa-solid ${emailButtonIcon}`"></i>
                    {{ emailButtonText }}
                </button>
            </div>
        </div>
    </div>
</template>
<style scoped>


ul {
    list-style-type: none;
    padding-left: 0;
}

li.list-item-with-line {
    position: relative;
    padding-left: 30px;
    margin: 0;
}

li.list-item-with-line::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 20px;
    height: 1px;
    background-color: #6950FF;
    transform: translateY(-50%);
}

li.list-item-with-line::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #6950FF;
    transform: translateY(0%);
}

li.list-item-with-line:last-child::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 50%;
    background-color: #6950FF;
    transform: translateY(0%);
}

</style>
