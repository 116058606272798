<script setup>
    import { useRouter } from 'vue-router';
    import { useAuthStore, storeToRefs } from "@/stores/Auth";
    import NSButton from "@/components/forms/NSButton.vue";

    const router = useRouter();

    import {computed, onMounted, onUnmounted, ref} from "vue";
    const authStore = useAuthStore();
    const { createMainSiteAccount } = authStore;
    const { user, userHasMainSiteAccount } = storeToRefs(authStore);

    const loggedIn = computed(() => !!user.value);

    const goToLogin = () => {
        window.location.href = '/login';
    }

    const isOnline = ref(navigator.onLine);


    const updateOnlineStatus = () => {
        isOnline.value = navigator.onLine;
    };


    onMounted(() => {
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);
    });

    onUnmounted(() => {
        window.removeEventListener('online', updateOnlineStatus);
        window.removeEventListener('offline', updateOnlineStatus);
    });
</script>

<template>
    <div class="flex flex-col space-y-6 py-6 px-4">
        <div class="gradient text-center text-white rounded-lg p-6 flex flex-col space-y-4">
            <h2 class="text-xl my-0 font-semibold text-white">My Favourites</h2>
            <p class="text-white my-0">Save contacts to your favourites for quick and immediate access.</p>
            <ns-button
                v-if="loggedIn && userHasMainSiteAccount"
                label="View Favourites"
                @click="router.push({name: 'favourites'})"
            />
            <ns-button
                v-else-if="loggedIn && !userHasMainSiteAccount"
                outline
                colour="white"
                class="text-white"
                label="Create Main Site Account"
                @click="createMainSiteAccount"
                :disabled="!isOnline"
                :class="{
                    'opacity-60': !isOnline,
                }"
            />
            <ns-button
                v-else
                outline
                colour="white"
                class="text-white"
                :label="isOnline ? 'Log in' : 'Cannot log in while offline'"
                @click="goToLogin"
                :disabled="!isOnline"
                :class="{
                    'opacity-60': !isOnline,
                }"
            />
        </div>

        <div class="gradient text-center text-white rounded-lg p-6 flex flex-col space-y-4">
            <h2 class="text-xl my-0 font-semibold text-white">View</h2>
            <p class="text-white my-0">Access your saved contact lists.</p>
            <n-s-button
                v-if="loggedIn && userHasMainSiteAccount"
                label="View your lists"
                @click="router.push({name: 'contacts-lists'})"
            />
            <n-s-button
                v-else-if="loggedIn && !userHasMainSiteAccount"
                outline
                colour="white"
                class="text-white"
                label="Create Main Site Account"
                @click="createMainSiteAccount"
                :disabled="!isOnline"
                :class="{
                    'opacity-60': !isOnline,
                }"
            />
            <n-s-button
                v-else
                outline
                colour="white"
                class="text-white"
                :label="isOnline ? 'Log in' : 'Cannot log in while offline'"
                @click="goToLogin"
                :disabled="!isOnline"
                :class="{
                    'opacity-60': !isOnline,
                }"
            />
        </div>

        <div class="gradient text-center text-white rounded-lg p-6 flex flex-col space-y-4" :class="{
            'opacity-60': !isOnline
        }">
            <h2 class="text-xl my-0 font-semibold text-white">Create Contact List</h2>
            <p class="text-white my-0">Create a new contact list for instant access to curated contacts.</p>
            <p class="text-red-600 my-0" v-if="!isOnline">
                You must be online to create contacts lists.
            </p>
            <span v-if="isOnline" class="w-full">
                <n-s-button v-if="loggedIn && userHasMainSiteAccount" class="w-full" label="Create a new list" @click="router.push({name: 'contacts-list-create'})" />
                <n-s-button v-else-if="loggedIn && !userHasMainSiteAccount" outline colour="white" class="text-white w-full" label="Create Main Site Account" @click="createMainSiteAccount" />
                <n-s-button v-else outline colour="white" class="text-white w-full" label="Log in" @click="goToLogin" />
            </span>
        </div>
    </div>
</template>

<style scoped>

</style>
