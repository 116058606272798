<script setup>
    import { ref } from 'vue';
    import { useContactsLists } from "@/stores/ContactsLists";
    import { useRouter } from "vue-router";

    const router = useRouter();

    const store = useContactsLists();
    const { createContactsList } = store;

    const
        currentStep = ref(1),
        name = ref(''),
        description = ref('')
    ;

    const nextStep = () => {
        createContactsList(name.value, description.value).then(() => {
            currentStep.value = 2;
        });
    };
</script>

<template>
    <div class="flex w-full flex-col py-4">
        <div class="grid grid-cols-2 gap-x-4 gap-y-2">
            <div
                class="rounded-full h-5 border"
                :class="{
                    'bg-ns-modern-green border-ns-modern-green': currentStep === 1,
                    'border-neutral-300': currentStep !== 1,
                }"
            ></div>
            <div
                class="rounded-full h-5 border"
                :class="{
                    'bg-ns-modern-green border-ns-modern-green': currentStep === 2,
                    'border-neutral-300': currentStep !== 2,
                }"
            ></div>

            <div class="col-span-full">
                <p class="text-center text-ns-deep-blue text-sm">Step {{ currentStep }} of 2</p>
            </div>
        </div>

        <div class="flex w-full flex-col space-y-4" v-if="currentStep === 1">
            <h1 class="text-center my-0">Create a New Contacts List</h1>

            <ns-text-input placeholder="Name" v-model="name" handle="name" />
            <ns-textarea placeholder="Additional Details" v-model="description" handle="description" />

            <ns-button label="Next" @click="nextStep" />
        </div>

        <div class="flex w-full flex-col space-y-4" v-else>
            <h1 class="text-center my-0">Add Contacts</h1>

            <div class="gradient text-center text-white rounded-lg p-6 flex flex-col space-y-4">
                <h2 class="text-xl my-0 font-semibold text-white">My Favourites</h2>
                <p class="text-white my-0">Head over to your saved contacts and add multiple people to your contacts list.</p>
                <ns-button label="Select" @click="router.push({name: 'favourites'})" />
            </div>

            <div class="gradient text-center text-white rounded-lg p-6 flex flex-col space-y-4">
                <h2 class="text-xl my-0 font-semibold text-white">Our People</h2>
                <p class="text-white my-0">Head over to our full people search where you can search and add multiple team members to a contacts list with ease.</p>
                <ns-button label="Select" @click="router.push({name: 'people-search'})" />
            </div>

            <div class="text-center pt-8 pb-4">
                <button @click="router.push({name: 'contacts-lists'})">Add Later</button>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
