import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue';
import { getAllResults, searchIndexedDB } from '@/idb-utils';

const useOfflinePeopleStore = defineStore('People', () => {
    const
        people = ref([]),
        search = ref(''),
        filters = ref([
            {
                name: 'Function',
                label: 'Filter by Function',
                open: false,
                key: 'people_function',
                values: [],
                active: [],
            },
            {
                name: 'Sector',
                label: 'Filter by Sector',
                open: false,
                key: 'people_sector',
                values: [],
                active: [],
            },
            {
                name: 'Office',
                label: 'Filter by Office',
                open: false,
                key: 'office',
                values: [],
                active: [],
            },
            {
                name: 'Leadership',
                label: 'Filter by Leadership',
                open: false,
                key: 'people_leadership_group',
                values: [],
                active: [],
            }
        ])
    ;

    const loadPeople = () => {
        return getAllResults('people').then((results) => {
            // sort by result.last_name
            people.value = results.sort((a,b) => {
                return a.last_name > b.last_name ? 1 : (a.last_name < b.last_name ? -1 : 0);
            });
        });
    }

    const loadFilterValues = (filterKey) => {
        const values = people.value.reduce((acc, item) => {
            if (item[filterKey]) {
                acc.push(...item[filterKey]);
            }
            return acc;
        }, [])
            .filter(value => !!value && !!value.title)
            .map(value => value.title);

        // Remove duplicates then sort alphabetically
        const filterValues = Array.from(new Set(values)).sort();
        // Update the available values for the given filter by filterKey
        filters.value.find(filter => filter.key === filterKey).values = filterValues;
    }

    const setFilter = (key, value) => {
        let filter = filters.value.find(filter => filter.key === key);

        if (filter) {
            if (filter.active.includes(value)) {
                filter.active = filter.active.filter(v => v !== value);
            } else {
                filter.active.push(value);
            }
        }
    }

    const clearFilter = (filter) => {
        if (filter.startsWith('Name: ')) {
            search.value = '';
            return;
        }

        filters.value.forEach(f => {
            if (f.active.includes(filter)) {
                f.active = f.active.filter(v => v !== filter);
            }
        });
    }

    const selectedFilters = computed(() => {
        let selected = [];

        if (search.value) {
            selected.push('Name: ' + search.value);
        }

        filters.value.forEach(filter => {
            if (filter.active.length) {
                filter.active.forEach(value => {
                    selected.push(value);
                });
            }
        });

        return selected;
    });

    const filteredPeople = computed(() => {
        let filtered = people.value;

        if (search.value) {
            filtered = filtered.filter(person => person.title.toLowerCase().includes(search.value.toLowerCase()));
        }

        filters.value.forEach(filter => {
            if (filter.active.length) {
                filtered = filtered?.filter(person => {
                    return filter.active?.some(value => {
                        return person[filter.key]?.some(v => v?.title?.toLowerCase() === value?.toLowerCase());
                    });
                });
            }
        });

        return filtered;
    });

    const nbHits = computed(() => filteredPeople.value.length);

    return {
        people,
        search,
        filters,
        nbHits,

        loadPeople,
        loadFilterValues,
        setFilter,
        clearFilter,

        selectedFilters,
        filteredPeople,
    };
});

export {
    useOfflinePeopleStore,
    storeToRefs
};
