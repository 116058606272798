import { defineStore, storeToRefs } from 'pinia'
import {computed, ref} from 'vue';
import { getAllResults, searchIndexedDB } from '@/idb-utils';

const useContactsLists = defineStore('ContactsLists', () => {
    /* State */
    const
        contactsLists = ref([]),
        favouriteContacts = ref([]),
        selectedPeople = ref([])
    ;

    /* Actions */
    const getCsrf = () => {
        return axios.get('/sanctum/csrf-cookie');
    }

    const getContactsLists = () => {
        return getCsrf().then(() => {
            return axios.get('/api/contacts-lists').then(response => {
                contactsLists.value = response.data;
            });
        });
    }

    const getFavouriteContacts = () => {
        return getCsrf().then(() => {
            return axios.get('/api/favourite-contacts').then(response => {
                favouriteContacts.value = response.data;
            }).catch(() => {});
        });
    }

    const addSelectedPeopleToFavourites = () => {
        return getCsrf().then(() => {
            return axios.post('/api/favourite-contacts', {
                people: selectedPeople.value
            }).then(response => {
                favouriteContacts.value = response.data;
            });
        });
    }

    const addSelectedPeopleToList = (listId) => {
        return getCsrf().then(() => {
            return axios.post(`/api/contacts-lists/${listId}`, {
                people: selectedPeople.value
            }).then(response => {
                let list = response.data;
                let index = contactsLists.value.findIndex(l => l.id === list.id);

                if (index !== -1) {
                    contactsLists.value[index] = list;
                } else {
                    contactsLists.value.push(list);
                }
            });
        });
    }

    const createContactsList = (name, description) => {
        return getCsrf().then(() => {
            return axios.post('/api/contacts-lists', {
                name: name,
                description: description
            }).then(response => {
                contactsLists.value.push(response.data);
            });
        });
    }

    const clearSelectedPeople = () => {
        selectedPeople.value = [];
    }

    const loadOfflineContactsLists = () => {
        return searchIndexedDB('contacts-lists');
    }

    const loadOfflineFavourites = () => {
        return searchIndexedDB('favourites');
    }

    /* Getters */

    return {
        /* State */
        contactsLists,
        favouriteContacts,
        selectedPeople,

        /* Actions */
        getContactsLists,
        getFavouriteContacts,
        addSelectedPeopleToFavourites,
        addSelectedPeopleToList,
        clearSelectedPeople,
        createContactsList,
        loadOfflineContactsLists,
        loadOfflineFavourites,

        /* Getters */
    }
});

export { useContactsLists, storeToRefs };
