<script setup>
import { useRouter } from "vue-router";
import Person from '@/components/people/Person.vue';
import {computed} from "vue";

const router = useRouter();

const props = defineProps({
    list: {
        type: Object,
        required: true
    }
});

const showContactsList = (id) => {
    router.push({ name: 'contacts-list', params: { id: id } })
}

const allPeople = computed(() => {
    if (Object.keys(props.list.people).length) {
        // people is an object with keys as functions and values as arrays of people
        // we just need to concatenate all the arrays
        return Object.values(props.list.people).reduce((acc, val) => acc.concat(val), []);
    }
});
</script>

<template>
    <div class="flex flex-col space-y-2">
        <div class="flex justify-between">
            <p class="whitespace-nowrap text-ns-deep-blue text-lg my-0">{{ list.name }} ({{ allPeople?.length ?? '0' }})</p>

            <a href="#" class="font-medium underline underline-offset-4" @click.prevent="showContactsList(list.id)">View full team</a>
        </div>

        <div class="flex flex-row space-x-4 overflow-x-scroll py-6 px-2 no-scrollbar" v-if="allPeople?.length" @click="showContactsList(list.id)" role="button">
            <div class="min-w-[300px] flex h-full" v-for="person in allPeople.slice(0, 3)">
                <person :person="person" :show-checkbox="false" />
            </div>

            <div v-if="allPeople?.length > 3">
                <div class="flex space-x-4 p-4 ns-shadow rounded-xl bg-white dark:bg-ns-deep-blue text-sm items-center h-full min-w-[200px] text-center justify-center">
                    <p>+ {{ allPeople?.length - 3 }} more...</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
