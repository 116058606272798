if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js').then(registration => {
            console.log('Service Worker registration successful with scope: ', registration.scope);

            // Check for updates
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            // New update available
                            console.log('New content is available; please refresh.');
                            navigator.serviceWorker.controller.postMessage({ action: 'new-version' });
                        } else {
                            // Content cached for offline use
                            console.log('Content is cached for offline use.');
                        }
                    }
                };
            };

            // Ensure the service worker is active before registering sync
            navigator.serviceWorker.ready.then(registration => {
                console.log('Service Worker is ready.');

                if (navigator.onLine) {
                    registration.active.postMessage({ action: 'sync-algolia' });
                }

                window.addEventListener('online', () => {
                    registration.active.postMessage({ action: 'sync-algolia' });
                });
            }).catch(error => {
                console.error('Service Worker ready promise failed:', error);
            });

        }).catch(error => {
            console.log('Service Worker registration failed: ', error);
        });
    });
} else {
    console.log('Service Worker is not supported in this browser.');
}
