import { openDB } from 'idb';

const DB_NAME = 'algolia-cache';
const VESSEL_STORE = 'vessels';
const CORRESPONDENT_STORE = 'correspondents';
const PEOPLE_STORE = 'people';
const CONTACTS_LISTS_STORE = 'contacts-lists';
const FAVOURITES_STORE = 'favourites';
const SAVED_PORTS_STORE = 'saved-ports';

const stores = [
    { name: VESSEL_STORE, keyPath: 'objectID' },
    { name: CORRESPONDENT_STORE, keyPath: 'objectID' },
    { name: PEOPLE_STORE, keyPath: 'objectID' },
    { name: CONTACTS_LISTS_STORE, keyPath: 'id' },
    { name: FAVOURITES_STORE, keyPath: 'id' },
    { name: SAVED_PORTS_STORE, keyPath: 'id' }
];
export async function getDb() {
    return openDB(DB_NAME, 1, {
        upgrade(db) {
            for (const { name, keyPath } of stores) {
                if (!db.objectStoreNames.contains(name)) {
                    db.createObjectStore(name, { keyPath });
                    console.log(`Object store '${name}' created with keyPath '${keyPath}'.`);
                }
            }
        },
    });
}

export async function storeResults(storeName, results) {
    try {
        const db = await getDb();
        const tx = db.transaction(storeName, 'readwrite');
        results.forEach(result => {
            // console.log(`Storing result in '${storeName}':`, result);
            tx.store.put(result);
        });
        await tx.done;
        console.log(`Stored ${results.length} results in IndexedDB for store '${storeName}'.`);
    } catch (error) {
        console.error(`Failed to store results in '${storeName}':`, error);
    }
}

export async function getAllResults(storeName) {
    try {
        const db = await getDb();
        const allResults = await db.getAll(storeName);
        console.log(`Retrieved ${allResults.length} results from IndexedDB store '${storeName}'.`);
        return allResults;
    } catch (error) {
        console.error(`Failed to retrieve results from '${storeName}':`, error);
        return [];
    }
}

export async function searchIndexedDB(storeName, query) {
    try {
        const db = await getDb();
        const tx = db.transaction(storeName, 'readonly');
        const store = tx.objectStore(storeName);
        const allResults = await store.getAll();

        const filteredResults = allResults.filter(item => {
            if (!item) return false;
            switch (storeName) {
                case VESSEL_STORE:
                    return (
                        (typeof item.name === 'string' && item.name.toLowerCase().includes(query.toLowerCase())) ||
                        (typeof item.ship_imo === 'string' && item.ship_imo.toLowerCase().includes(query.toLowerCase()))
                    );
                case CORRESPONDENT_STORE:
                    return (
                        (typeof item.name === 'string' && item.name.toLowerCase().includes(query.toLowerCase())) ||
                        (typeof item.port === 'string' && item.port.toLowerCase().includes(query.toLowerCase())) ||
                        (typeof item.country === 'string' && item.country.toLowerCase().includes(query.toLowerCase()))
                    );
                case PEOPLE_STORE:
                case CONTACTS_LISTS_STORE:
                case FAVOURITES_STORE:
                    // These stores search only by name
                    return typeof item.name === 'string' && item.name.toLowerCase().includes(query.toLowerCase());
                default:
                    return false;
            }
        });

        console.log(`Found ${filteredResults.length} results matching the query in store '${storeName}'.`);
        return filteredResults;
    } catch (error) {
        console.error(`Failed to search results in '${storeName}':`, error);
        return [];
    }
}
