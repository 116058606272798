<script setup>
import { ref, reactive, onMounted, computed } from 'vue';
import algoliasearch from 'algoliasearch/lite';
import SingleVessel from './SingleVessel.vue';
import OfflineVesselSearch from './OfflineVesselSearch.vue';
import { searchIndexedDB, storeResults } from '../idb-utils';

const searchClient = algoliasearch('QI33H8CI46', '8f30cac1e37ce61c0f30027ba78ad5c1');
const indexName = 'vessels'; // Use a string for the index name
const prefix = window?.ALGOLIA_USE_DEV_INDEXES === true ? 'dev_' : ''
const searchIndex = `${prefix}${indexName}`;

const searchInput = ref('');
const showSuggestions = ref(true); // start as true to ensure it exists
const resultsVisible = ref(true); // start as true to ensure it exists

const state = reactive({
    tags: [],
    vessel: null,
    results: [] // Add results to the state to store search results
});

// Computed property to check online status
const isOnline = computed(() => navigator.onLine);

function currentDate() {
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
    return date;
}

// Helper functions for caching
function storeResultsLocally(results, storeName) {
    storeResults(storeName, results);
}
function getCachedResults() {
    const cached = localStorage.getItem('algolia_search_results');
    return cached ? JSON.parse(cached) : [];
}

// Function to load results from IndexedDB based on search term
async function loadResultsFromIndexedDB(searchQuery, storeName) {
    try {
        const results = await searchIndexedDB(storeName, searchQuery);
        console.log('Offline search results:', results);
        return results;
    } catch (error) {
        console.error('Error loading results from IndexedDB:', error);
        throw error;
    }
}

onMounted(async () => {
// Check for an existing 'search' query parameter
    const urlParams = new URLSearchParams(window.location.search);
    const initialSearch = urlParams.get('search');

    if (initialSearch) {
        searchInput.value = initialSearch;
        state.tags = [initialSearch];
        resultsVisible.value = true;
    } else {
        resultsVisible.value = false;
    }

    if (!navigator.onLine) {
        console.log("Navigator not online - mounted - get cached results");
        // Load all results from IndexedDB when offline
    }

});


const noop = () => {}; // No-operation function for default cases

function updateSearchQuery() {
    const urlParams = new URLSearchParams();
    if (state.tags.length > 0) {
        urlParams.set('search', state.tags[0]);
    } else {
        urlParams.delete('search');
    }
    history.replaceState(null, '', '?' + urlParams.toString());
};

function clearSearch(refine = noop) {
    console.log('Clearing search...');
    searchInput.value = '';
    state.tags = [];
    if (isOnline.value) {
        refine('');
    }
    showSuggestions.value = false;
    resultsVisible.value = false;
    state.results = [];
    updateSearchQuery();
}

function removeTag(tag, refine = noop) {
    console.log('Removing tag:', tag);
    state.tags = state.tags.filter(t => t !== tag);
    if (tag === searchInput.value) {
        searchInput.value = '';
    }
    resultsVisible.value = state.tags.length > 0;

    if (state.tags.length > 0) {
        refreshSearch(refine);
    } else {
        state.results = [];
    }

    updateSearchQuery();
}

function refreshSearch(refine = noop) {
    console.log('Refreshing search...');
    const query = state.tags.join(' ');
    if (isOnline.value) {
        refine(query);
    }
    showSuggestions.value = false;
    resultsVisible.value = !!query; // Show results only if query is not empty
}

async function triggerSearch(searchQuery, refine = noop, storeName = 'vessels') {
    console.log('Triggering search with searchQuery:', searchQuery);
    if (!searchQuery) {
        searchQuery = searchInput.value;
    }

    if (searchQuery) {
        resultsVisible.value = true;

        if (navigator.onLine) {
            console.log('Online Search');
            state.tags = [searchInput.value];
            searchClient.initIndex(indexName).search(searchQuery).then(({ hits }) => {
                state.results = hits;
                storeResultsLocally(hits, storeName);
                refine(searchQuery);
            });
        } else {
            console.log('Offline Search');
            try {
                state.tags = [searchQuery];
                state.results = await loadResultsFromIndexedDB(searchQuery, storeName);
            } catch (error) {
                console.error('Error during offline search from IndexedDB:', error);
            }
            showSuggestions.value = false;
            updateSearchQuery();
        }

        showSuggestions.value = false;
        updateSearchQuery();
    } else {
        resultsVisible.value = false;
    }
}

function selectItem(hit, refine = noop) {
    searchInput.value = hit.name;
    state.tags = [hit.name]; // Restrict to a single tag
    if (isOnline.value) {
        refine(hit.name);
    }
    showSuggestions.value = false;
    resultsVisible.value = true;
    updateSearchQuery();
};

// Added this to show suggestions when typing
function onInput(value, refine = noop) {
    searchInput.value = value;
    if (isOnline.value) {
        refine(value);
    }
    showSuggestions.value = true;
}

</script>
<template>
    <div>
        <div class="banner">
            <div class="inner">
                <div class="info relative flex mt-4 md:mt-8 mb-4 items-center">
                    <title-tool-tip title="Vessel Search">
                        <template #tooltip-content>
                            <p class="text-white">Use our simple search tool to find any vessel insured by NorthStandard (formerly North and Standard Club) and key insurance documents. This includes all vessels insured by:</p>
                            <ul class="list-disc pl-4">
                                <li class="mb-2">NorthStandard Limited</li>
                                <li class="mb-2">NorthStandard EU DAC (previously North of England P&I DAC)</li>
                                <li class="mb-2">The Standard Club UK Ltd</li>
                                <li class="mb-2">The Standard Club Asia Ltd.</li>
                                <li class="mb-2">The Standard Club Ireland DAC</li>
                            </ul>
                        </template>
                    </title-tool-tip>
                </div>
            </div>
        </div>

        <ais-instant-search
            v-if="isOnline"
            :search-client="searchClient"
            :index-name="searchIndex"
            :stalled-search-delay="250"
        >
            <ais-autocomplete>
                <template v-slot="{ currentRefinement, indices, refine }">
                    <div class="autocomplete-container w-full rounded-lg p-4" style="box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);">
                        <form @submit.prevent="triggerSearch(refine)">
                            <div class="relative flex flex-col w-full md:flex-row space-y-2 md:space-y-0 md:space-x-2 items-center">
                                <div class="relative flex flex-1 items-center w-full xl:w-auto bg-ns-gray rounded-lg focus:outline-none focus:ring-0">
                                    <i class="fas fa-search px-4 text-gray-400"></i>
                                    <input
                                        v-model="searchInput"
                                        type="search"
                                        placeholder="Search by vessel name or IMO no"
                                        class="flex-1 form-input drop-shadow-none font-semibold bg-transparent py-3 rounded-lg focus:outline-none focus:ring-0"
                                        @input="onInput($event.target.value, refine)"
                                    />
                                    <div class="bg-white absolute top-full w-full z-10" style="box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); border-radius: 0.5rem;">
                                        <ul
                                            v-if="showSuggestions && currentRefinement"
                                            class="overflow-y-auto max-h-60"
                                        >
                                            <li
                                                v-for="index in indices"
                                                :key="index.indexId"
                                            >
                                                <ul>
                                                    <li
                                                        v-for="hit in index.hits"
                                                        :key="hit.objectID"
                                                        class="cursor-pointer bg-white font-semibold hover:bg-ns-gray py-2 px-4"
                                                        @click="selectItem(hit, refine)"
                                                    >
                                                        <ais-highlight attribute="name" :hit="hit"/>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex md:space-x-2 w-full flex-col md:flex-row md:w-auto">
                                    <button
                                        type="submit"
                                        class="w-full md:w-auto inline-block button button--green focus:outline-none"
                                    >
                                        Search
                                    </button>
                                    <button
                                        type="button"
                                        class="w-full font-semibold md:w-auto px-4 py-2 text-ns-deep-blue rounded-lg hover:text-red-600 focus:outline-none"
                                        @click="clearSearch(refine)">
                                        CLEAR
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div
                        v-if="resultsVisible"
                        class="flex flex-col md:flex-row gap-4 mt-8 w-full items-start md:items-center"
                    >
                        <div class="md:flex-none">
                            <h4 class="my-2 text-left md:text-right">Search results for</h4>
                        </div>
                        <div class="flex-grow flex flex-wrap items-start md:items-center">
                        <span
                            v-for="tag in state.tags"
                            :key="tag"
                            class="bg-ns-dark-blue rounded-lg py-2 px-4 mr-2 mb-2 text-white inline-flex items-center"
                        >
                            <span>{{ tag }}</span>
                            <button
                                :aria-label="`Remove the '${tag}' search refinement`"
                                type="button"
                                class="ml-2 hover:text-ns-dark-green"
                                @click="() => removeTag(tag, refine)"
                            >
                                <i class="fa-regular fa-xmark"></i>
                            </button>
                        </span>
                        </div>
                    </div>
                </template>
            </ais-autocomplete>

            <ais-stats v-if="resultsVisible">
                <template v-slot="{ nbHits }">
                    <div
                        v-if="nbHits !== 0"
                        class="mb-8"
                    >
                        <p class="font-heading">Your search returned <span class="font-semibold">{{ nbHits }} results</span></p>
                    </div>
                </template>
            </ais-stats>

            <ais-hits v-if="resultsVisible">
                <template v-slot="{ items }">
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 w-full mb-20">
                        <SingleVessel
                            v-for="item in items"
                            :key="item.objectID"
                            :vessel="item"
                        />
                    </div>
                </template>
            </ais-hits>
        </ais-instant-search>
        <div v-else>
            <OfflineVesselSearch
                :results="state.results"
                :search-input="searchInput"
                :tags="state.tags"
                :results-visible="resultsVisible"
                @trigger-search="triggerSearch"
                @clear-search="clearSearch"
                @remove-tag="removeTag"
            />
        </div>
        <div class="my-8 text-white">
            <accordion title="Read full terms">
                <template #default>
                    <p class="text-white">The vessel search is compiled from the records of the above businesses as of {{currentDate()}}.</p>

                    <p class="text-white">As we refresh the vessel search daily, there may be a brief delay while we synchronise the data. If you're unable to find your chosen vessel by name or IMO number, please try again in 10 minutes. If you still can't find your vessel after this time, it's unlikely that we insure it.</p>

                    <p class="text-white">Vessels insured by us are subject to the Club rules and member's terms of entry, including all payment of calls.</p>

                    <p class="text-white">If the Blue Card search is used – or reproduced and shown to any party – as evidence of insurance, please note:</p>
                    <ul class="list-disc pl-4 text-white">
                        <li class="mb-2">The search information only relates to the date the database is accessed or the information is reproduced.</li>
                        <li class="mb-2">Insurance is subject to the Club rules and member's terms of entry.</li>
                        <li class="mb-2">The Club also does not consent to act as a guarantor or to be sued directly in any jurisdiction.</li>
                        <li class="mb-2">It is not considered evidence of any undertaking, financial or otherwise, by the Club to any other party.</li>
                    </ul>

                </template>
            </accordion>
        </div>
        <div class="my-8">
            <accordion title="Regulatory information">
                <template #default>
                    <h5 class="text-white">Accepting sample Blue Cards – NorthStandard Limited</h5>

                    <p class="text-white">Please note the following P&I providers are member P&I Clubs (affiliate or reinsured subsidiary) of the <a class="text-ns-modern-green hover:text-ns-modern-green/85" href="https://www.igpandi.org/" target="_blank" title="International Group of P&I Clubs">International Group of P&I Clubs:</a></p>

                    <ul class="list-disc pl-4 text-white">
                        <li class="mb-2">NorthStandard Limited</li>
                        <li class="mb-2">NorthStandard EU DAC (previously North of England P&I DAC)</li>
                        <li class="mb-2">The Standard Club UK Ltd</li>
                        <li class="mb-2">The Standard Club Asia Ltd.</li>
                        <li class="mb-2">The Standard Club Ireland DAC</li>
                    </ul>

                    <p class="text-white">For more information, please see the list of International Group member (affiliate and reinsured subsidiary) P&I Clubs on the International Group's website.</p>

                    <p class="text-white">To see the complete list of International Group Clubs and their affiliated associations, please <a class="text-ns-modern-green hover:text-ns-modern-green/85" href="https://www.igpandi.org/group-clubs/" target="_blank" title="Click here">click here.</a></p>

                    <p class="text-white">Convention blue cards issued by the above providers must only be used according to the guidelines set out in <a class="text-ns-modern-green hover:text-ns-modern-green/85" href="https://docs.imo.org/Shared/Download.aspx?did=87476" target="_blank" title="IMO Circular Letter No. 3464">IMO Circular Letter No. 3464</a>.</p>

                    <p class="text-white">If you need any more information, please <a class="text-ns-modern-green hover:text-ns-modern-green/85" href="/offices" title="Get in touch with NorthStandard">get in touch with us</a> or the <a class="text-ns-modern-green hover:text-ns-modern-green/85" href="https://www.igpandi.org/contact/" target="_blank" title="International Group">International Group</a>.</p>

                </template>
            </accordion>
        </div>

    </div>
</template>
