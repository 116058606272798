import { createRouter, createWebHistory, useRoute, useRouter } from 'vue-router';

// Components
import VesselSearch from './views/VesselSearch.vue';
import CorrespondentSearch from './views/CorrespondentSearch.vue';
import LandingScreen from './views/LandingScreen.vue';
import PeopleSearch from './views/PeopleSearch.vue';
import Account from './views/Account.vue';
import EmergencyContact from './views/EmergencyContact.vue';
import PeopleDashboard from "./views/PeopleDashboard.vue";

import FavouriteContacts from "./views/contacts-lists/Favourites.vue";
import ShowContactsList from "./views/contacts-lists/Show.vue";
import ContactsListsIndex from "./views/contacts-lists/Index.vue";
import CreateContactsList from "./views/contacts-lists/Create.vue";

//Routes
const routes = [
    {
        path: '/',
        name: 'index',
        component: LandingScreen,
        meta: { theme: 'dark' }
    },
    {
        path: '/vessel-search',
        name: 'vessel-search',
        component: VesselSearch,
        meta: { theme: 'light' }
    },
    {
        path: '/correspondents-search',
        name: 'correspondents-search',
        component: CorrespondentSearch,
        meta: { theme: 'light' }
    },
    {
        path: '/people',
        name: 'people',
        component: PeopleDashboard,
        meta: { theme: 'light' }
    },
    {
        path: '/people/search',
        name: 'people-search',
        component: PeopleSearch,
        meta: { theme: 'light' }
    },
    {
        path: '/people/favourites',
        name: 'favourites',
        component: FavouriteContacts,
        meta: { theme: 'light' }
    },
    {
        path: '/people/contacts-lists',
        name: 'contacts-lists',
        component: ContactsListsIndex,
        meta: { theme: 'light' }
    },
    {
        path: '/people/contacts-lists/create',
        name: 'contacts-list-create',
        component: CreateContactsList,
        meta: { theme: 'light' }
    },
    {
        path: '/people/contacts-lists/:id',
        name: 'contacts-list',
        component: ShowContactsList,
        meta: { theme: 'light' }
    },
    {
        path: '/my-account',
        name: 'my-account',
        component: Account,
        meta: { theme: 'light' }
    },
    {
        path: '/emergency-contact',
        name: 'emergency-contact',
        component: EmergencyContact,
        meta: { theme: 'dark' }
    },
    {
        path: '/login',
        name: 'login',
        beforeEnter(to, from, next) {
            window.location.href = '/login';
        }
    }
];

//Route history
const router = createRouter({
    history: createWebHistory(),
    routes,
});

//Theme selector
router.beforeEach((to, from, next) => {
    const theme = to.meta.theme || 'light';
    document.body.className = theme;
    next();
});


const navigateTo = (path) => {
    router.push(path);
}

export { router, navigateTo, useRoute, useRouter };
