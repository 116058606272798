import { defineAsyncComponent } from "vue";

const defineAppComponents = (app) => {
    app.component('ns-text-input', defineAsyncComponent(() => import('@/components/forms/NSTextInput.vue')));
    app.component('ns-button', defineAsyncComponent(() => import('@/components/forms/NSButton.vue')));
    app.component('ns-checkbox', defineAsyncComponent(() => import('@/components/forms/NSCheckbox.vue')));
    app.component('ns-textarea', defineAsyncComponent(() => import('@/components/forms/NSTextarea.vue')));

    app.component('tooltip', defineAsyncComponent(() => import('@/components/Tooltip.vue')));
    app.component('title-tool-tip', defineAsyncComponent(() => import('@/components/utilities/TitleToolTip.vue')));
    app.component('accordion', defineAsyncComponent(() => import('@/components/utilities/SingleAccordion.vue')));
}

export { defineAppComponents };
