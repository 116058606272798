<script setup>
    import {computed, onMounted, onUnmounted, ref} from 'vue';
    import { fetchAndStoreContactsLists, fetchAndStoreFavourites } from "@/sw.js";
    import { useContactsLists, storeToRefs } from '@/stores/ContactsLists';

    import ContactsList from '@/components/contacts-lists/List.vue';

    const store = useContactsLists();

    const { contactsLists } = storeToRefs(store);

    const {
        getContactsLists,
        loadOfflineContactsLists,
        loadOfflineFavourites
    } = store;

    const isOnline = ref(navigator.onLine);

    const updateOnlineStatus = () => {
        isOnline.value = navigator.onLine;
    };

    onMounted(() => {
        if (isOnline.value) {
            getContactsLists();
            fetchAndStoreContactsLists();
            fetchAndStoreFavourites();
        } else {
            loadOfflineContactsLists();
            loadOfflineFavourites();
        }
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);
    });

    onUnmounted(() => {
        window.removeEventListener('online', updateOnlineStatus);
        window.removeEventListener('offline', updateOnlineStatus);
    });
</script>

<template>
    <div>
        <h1>Contacts Lists</h1>

        <div class="flex flex-col space-y-6" v-if="contactsLists.length">
            <contacts-list v-for="list in contactsLists" :list="list" />
        </div>

        <div class="flex flex-col space-y-2" v-else>
            <p class="text-ns-deep-blue py-8">
                You don’t appear to have any contact lists saved.
            </p>

            <p class="text-ns-deep-blue">
                Click <router-link class="text-ns-dark-blue" :to="{name: 'contacts-list-create'}">here</router-link> to create a new contact list
            </p>
        </div>
    </div>
</template>

<style scoped>

</style>
