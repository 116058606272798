<script setup>
import {computed, onMounted, onUnmounted, ref} from "vue";
    import { useContactsLists, storeToRefs } from "@/stores/ContactsLists";

    const store = useContactsLists();
    const { selectedPeople } = storeToRefs(store);

    const props = defineProps({
        person: {
            type: Object,
            required: true
        },
        showCheckbox: {
            type: Boolean,
            default: true
        },
        compact: {
            type: Boolean,
            default: false
        }
    });

    const isOnline = ref(navigator.onLine);
    const fallback = ref(false);

    const updateOnlineStatus = () => {
        isOnline.value = navigator.onLine;
    };


    onMounted(() => {
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);
    });

    onUnmounted(() => {
        window.removeEventListener('online', updateOnlineStatus);
        window.removeEventListener('offline', updateOnlineStatus);
    });

    const selected = computed({
        get: () => selectedPeople.value.includes(props.person.id),
        set: value => {
            if (value) {
                selectedPeople.value.push(props.person.id);
            } else {
                selectedPeople.value = selectedPeople.value.filter(id => id !== props.person.id);
            }
        }
    });

    const avatarUrl = computed(() => {
        if (props.person.avatar_url) {
            return props.person.avatar_url;
        }

        if (props.person.avatar?.url && props.person.avatar.url.length > 0) {
            return props.person.avatar.url;
        }

        // if avatar is an array:
        if (props.person.avatar && props.person.avatar.length > 0) {
            return props.person.avatar[0];
        }

        return null;
    });

    const truncate = (text, length) => {
        if (text.length > length) {
            return text.substring(0, length) + '...';
        }

        return text;
    }

    const displayCheckbox = computed(() => {
        return props.showCheckbox && isOnline.value;
    });
</script>

<template>
    <div class="flex p-4 ns-shadow rounded-xl bg-white dark:bg-ns-deep-blue text-sm lg:text-base h-full" v-if="person" :class="{
        'flex-col': compact
    }">
        <div class="flex flex-row space-x-4 w-full">
            <div
                v-if="(isOnline)"
            >
                <div class="rounded-xl bg-gray-200 dark:bg-gray-800 bg-cover bg-center relative overflow-hidden" :class="{
                    'min-w-28 w-28 aspect-3/4': !compact,
                    'min-w-20 w-20 aspect-1/1': compact
                }">
                    <span class="group">
                        <img
                            v-if="avatarUrl"
                            @error="fallback = true"
                            class="w-full h-full object-cover object-center transition duration-300 group-hover:scale-105"
                            :src="avatarUrl"
                            :alt="person?.full_name"
                        >
                        <i v-else class="fa-solid fa-user text-neutral-400 text-5xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition duration-300 group-hover:scale-110"></i>
                    </span>
                </div>
            </div>

            <div class="flex flex-col space-y-1 w-full text-sm break-all">
                <div class="grow w-full">
                    <div class="flex justify-between w-full text-ns-deep-blue dark:text-white">
                        <p
                            :class="{
                                'whitespace-nowrap': compact
                            }" class="font-semibold my-0 transition duration-300"
                            style="white-space:pre-wrap; word-break:break-word;"
                        >
                            {{ person.title }}
                        </p>

                        <!-- Checkbox -->
                        <ns-checkbox v-if="displayCheckbox" :label="''" :dark="false" :handle="person.slug" v-model="selected" />
                    </div>
                    <span class="font-light block w-11/12" style="white-space:pre-wrap; word-break:break-word;" v-if="person.job_title">{{ person.job_title }}</span>
                </div>

                <div class="flex flex-col space-y-1 w-full text-sm" v-if="!compact">
                    <p class="my-0" v-if="person.tel_office">
                        <span class="font-semibold">Tel (Office):</span> <a :href="`tel:${person.tel_office }`">{{ person.tel_office }}</a>
                    </p>
                    <p class="my-0" v-if="person.tel_mobile">
                        <span class="font-semibold">Tel (Mobile):</span> <a :href="`tel:${person.tel_mobile }`">{{ person.tel_mobile }}</a>
                    </p>
                    <p class="my-0" v-if="person.email">
                        <span class="font-semibold">Email:</span> <a :href="`mailto:${person.email}`" class="transition duration-300 hover:text-ns-dark-blue">{{ person.email }}</a>
                    </p>
                    <p v-if="person.office?.length">
                        <span class="font-semibold">Office:</span> {{ person.office[0].title }}
                    </p>
                </div>
            </div>
        </div>

        <div class="flex flex-col space-y-2 w-full text-sm pt-2" v-if="compact">
            <p class="my-0" v-if="person.tel_office">
                <span class="font-semibold">Tel (Office):</span> <a :href="`tel:${person.tel_office }`">{{ person.tel_office }}</a>
            </p>
            <p class="my-0" v-if="person.tel_mobile">
                <span class="font-semibold">Tel (Mobile):</span> <a :href="`tel:${person.tel_mobile }`">{{ person.tel_mobile }}</a>
            </p>
            <p class="my-0" v-if="person.email">
                <span class="font-semibold">Email:</span> <a :href="`mailto:${person.email}`" class="transition duration-300 hover:text-ns-dark-blue">{{ person.email }}</a>
            </p>
            <p class="my-0" v-if="person.office?.length">
                <span class="font-semibold">Office:</span> {{ person.office[0].title }}
            </p>
        </div>
    </div>
</template>

<style scoped>

</style>
