<script setup>
import { reactive, computed, ref } from 'vue';
import algoliasearch from 'algoliasearch/lite';

import Person from '@/components/people/Person.vue';

import { isEmpty } from 'lodash';

import { history as historyRouter } from 'instantsearch.js/es/lib/routers';
import { simple as simpleStateMapping } from 'instantsearch.js/es/lib/stateMappings';
import { singleIndex as singleIndexMapping } from 'instantsearch.js/es/lib/stateMappings';

const emit = defineEmits(['items-updated']);

const state = reactive({
    searchClient: algoliasearch(
        window.ALGOLIA_APP_ID,
        window.ALGOLIA_PUBLIC
    )
});

const data = reactive({
    userOpenedFilters: false,
    filtersOpen: [],
    sortByOpen: false,
});

const props = defineProps({
    loggedIn: {
        type: Boolean,
        required: false,
        default: false
    },
    searchIndex: {
        type: String,
        required: true
    },
    showFilters: {
        type: Boolean,
        required: false,
        default: () => { return false }
    },
    filters: {
        type: Array,
        required: false,
        default: () => { return [] }
    },
    isSearch: {
        type: Boolean,
        required: false,
        default: false
    },
    query: {
        type: String,
        required: false,
        default: () => { return null }
    },
    searchTitle: {
        type: String,
        required: false,
        default: 'Search results'
    },
    eventsListing: {
        type: Boolean,
        required: false,
        default: false
    },
    // currentUser: {
    //     type: Object,
    //     required: false,
    //     default: {},
    // }
});

const hitsPerPageTooltip = ref(false);

const searchIndex = computed(() => {
    const prefix = window?.ALGOLIA_USE_DEV_INDEXES === true ? 'dev_' : ''
    return `${prefix}${props.searchIndex}`
});

const showFilters = computed(() => {
    return props.filters.length > 0
});

const routing = reactive({
    router: historyRouter({
        createURL({ qsModule, routeState, location }) {
            const baseUrl = `${location.origin}${location.pathname}`;
            const queryParameters = {};

            if (routeState.query) {
                queryParameters.query = encodeURIComponent(routeState.query);
            }

            if (routeState.page !== 1) {
                queryParameters.page = routeState.page;
            }

            if (routeState.insights_tags) {
                queryParameters[`insights_tags[]`] = routeState.insights_tags.map(encodeURIComponent);
            }

            const queryString = qsModule.stringify(queryParameters, {
                addQueryPrefix: true,
                arrayFormat: 'repeat',
            });

            return `${baseUrl}${queryString}`;
        },

        parseURL({ qsModule, location }) {
            const { query = '', page, insights_tags = [] } = qsModule.parse(
                location.search.slice(1)
            );

            const allInsightsTags = Array.isArray(insights_tags)
                ? insights_tags
                : [insights_tags].filter(Boolean);

            return {
                query: decodeURIComponent(query),
                page,
                insights_tags: allInsightsTags.map(decodeURIComponent),
            };
        },
    }),

    stateMapping: {
        stateToRoute(uiState) {
            const indexUiState = uiState[searchIndex.value] || {};

            return {
                query: indexUiState.query,
                page: indexUiState.page,
                insights_tags: indexUiState.refinementList && indexUiState.refinementList.insights_tags,
            };
        },

        routeToState(routeState) {
            return {
                [searchIndex.value]: {
                    query: routeState.query,
                    page: routeState.page || 1,
                    refinementList: {
                        insights_tags: routeState.insights_tags,
                    },
                },
            };
        },
    },
});

function toggleFilters (index) {
    if (data.filtersOpen.includes(index)) {
        data.filtersOpen = data.filtersOpen.filter((item) => item !== index)
    } else {
        data.filtersOpen.push(index);
    }
};

function toggleSortBy () {
    if (data.sortByOpen) {
        data.sortByOpen = false
    } else {
        data.sortByOpen = true
    }
};

function clearSortByState (items, currentItem) {
    items.forEach((item) => {
        if (item === currentItem) {
            item.isRefined = true
        } else {
            item.isRefined = false
        }
    })
};

const initialUiState = () => {
    if (!isEmpty(props.query)) {
        return {
            [searchIndex.value]: {
                query: props.query
            }
        };
    }
    return {};
};

const itemsUpdated = (items, { results }) => {
    emit('items-updated', items);
    return items;
};

const setItemsPerPage = (refine, value) => {
    refine(value);

    const el = document.getElementById('search_results_section');
    if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
    }
}
</script>
<template>
    <ais-instant-search
        ref="algoliaSearch"
        :search-client="state.searchClient"
        :index-name="searchIndex"
        :initial-ui-state="initialUiState()"
        :stalled-search-delay="250"
        :routing="routing"
    >
        <slot name="config" />

        <div class="flex w-full flex-col space-y-4">
            <div class="flex w-full">
                <ns-button
                    :prepend-icon="data.userOpenedFilters ? 'fa-light fa-times' : 'fa-light fa-filter'"
                    class="w-full transition-all"
                    @click="data.userOpenedFilters = !data.userOpenedFilters"
                >
                    <span v-if="data.userOpenedFilters">Clear and hide search</span>
                    <span v-else>Search our people</span>
                </ns-button>
            </div>

            <transition name="slide-down">
                <aside
                    class="w-full p-4 shadow-lg rounded-lg bg-white dark:bg-ns-deep-blue dark:text-white transition duration-300"
                    v-if="showFilters && data.userOpenedFilters"
                >
                    <div class="w-full">
                        <div
                            class="w-full grid md:grid-cols-2 xl:grid-cols-4 gap-6"
                        >
                            <ais-search-box show-loading-indicator class="col-span-full">
                                <template v-slot="{ currentRefinement, isSearchStalled, refine }">
                                    <div class="col-span-full flex relative">
                                        <i
                                            aria-hidden="true"
                                            class="fas fa-search absolute top-1/2 left-3 -translate-y-1/2 text-ns-deep-blue dark:text-white/60 pointer-events-none transition-all"
                                        />
                                        <input
                                            type="search"
                                            name="query"
                                            class="w-full bg-gray-100 text-ns-deep-blue pl-9 pr-4 py-2 rounded-lg dark:bg-ns-deep-blue dark:text-white dark:border dark:border-white"
                                            :value="currentRefinement"
                                            placeholder="Search by name"
                                            @input="refine($event.currentTarget.value)"
                                        >
                                    </div>
                                </template>
                            </ais-search-box>
                            <ais-refinement-list
                                v-for="(filter, index) in filters"
                                :key="index"
                                :attribute="filter.key"
                                :limit="1000"
                                operator="and"
                                class="w-full z-0"
                                :sortBy="['name:asc']"
                            >
                                <template v-slot="{ items, refine, createURL }">
                                    <div class="rounded-lg text-ns-deep-blue relative z-0 border border-ns-deep-blue dark:border-white dark:text-white">
                                        <button @click="toggleFilters(index)" class="w-full text-left flex justify-between px-4 py-2 z-0">
                                        <span v-if="filter.label" class="text-base my-0">
                                            {{ filter.label }}
                                        </span>
                                            <i
                                                class="fa-sharp fa-regular fa-chevron-down text-xl transition duration-300"
                                                :class="{
                                                    'flip-x' : data.filtersOpen.includes(index)
                                                }"
                                            ></i>
                                        </button>
                                        <transition name="slide-down">
                                            <div
                                                class="flex flex-col space-y-3 p-4 pt-2 z-50 w-full"
                                                v-if="data.filtersOpen.includes(index)"
                                            >
                                                <a
                                                    v-for="item in items"
                                                    :key="item.value"
                                                    :href="createURL(item)"
                                                    class="flex items-center space-x-2"
                                                    :class="{
                                                        'text-gray-700': !item.isRefined,
                                                        'text-black': item.isRefined
                                                    }"
                                                    @click.prevent="refine(item.value)"
                                                >
                                                    <input @click.prevent="refine(item.value)" :checked="item.isRefined" type="checkbox" class="h-6 w-6 pointer-events-none" aria-hidden="true" tabindex="-1">
                                                    <div class="flex-grow flex items-center justify-between w-full">
                                                        <ais-highlight :hit="item" attribute="item" />
                                                    </div>
                                                </a>
                                            </div>
                                        </transition>
                                    </div>
                                </template>
                            </ais-refinement-list>
                        </div>
                    </div>
                </aside>
            </transition>
            <div class="col-span-full grid gap-8">
                <div class="flex flex-wrap gap-8">
                    <ais-current-refinements v-if="showFilters" class="grow flex flex-wrap gap-x-4 gap-y-2">
                        <template v-slot="{ items, createURL }">
                            <div class="flex flex-wrap gap-6" id="search_results_section">
                                <span v-if="showFilters && data.userOpenedFilters" class="text-xl font-heading font-semibold md:text-2xl lg:text-3xl">Search results for</span>
                                <div
                                    v-for="item in items"
                                    :key="item.attribute"
                                    class="flex flex-wrap gap-2"
                                >
                                    <div
                                        v-for="refinement in item.refinements"
                                        :key="[
                                            refinement.attribute,
                                            refinement.type,
                                            refinement.value,
                                            refinement.operator
                                        ].join(':')"
                                        class="flex flex-wrap"
                                    >
                                        <a
                                            :href="createURL(refinement)"
                                            class="inline-flex gap-x-2 items-center py-2 p-5 pr-12 bg-ns-dark-blue text-white rounded-lg transition duration-300 relative text-sm"
                                            @click.prevent="item.refine(refinement)"
                                        >
                                            <span>{{ refinement.label }}</span>
                                            <i class="fa-sharp fa-solid fa-times pointer-events-none absolute right-5 top-1/2 -translate-y-1/2"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </ais-current-refinements>

                    <ais-clear-refinements class="flex items-center">
                        <template v-slot="{ canRefine, refine, createURL }">
                            <div v-show="canRefine">
                                <button
                                    :href="createURL()"
                                    type="button"
                                    class="font-medium font-heading inline-block border-b border-ns-deep-blue transition duration-300 hover:text-ns-dark-blue hover:border-ns-dark-blue"
                                    @click.prevent="refine()"
                                >
                                    Clear filters
                                </button>
                            </div>
                        </template>
                    </ais-clear-refinements>
                </div>
                <ais-stats class="text-base flex gap-2 md:items-center md:justify-between flex-col md:flex-row">
                    <template v-slot="{ nbHits }">
                        <span><span>Your search returned </span><span class="font-bold">{{ nbHits }} {{ nbHits === 1 ? 'result' : 'results' }}</span></span>
                        <slot name="actions"></slot>
                    </template>
                </ais-stats>
                <ais-hits
                    :transform-items="itemsUpdated"
                    class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-6"
                >
                    <template v-slot="{ items }">
                        <person
                            v-for="item in items"
                            :key="item.objectID"
                            :person="item"
                            :show-checkbox="loggedIn"
                        >
                            <template #title>
                                <ais-highlight
                                    :hit="item"
                                    attribute="title"
                                />
                            </template>
                        </person>
                    </template>
                </ais-hits>
                <ais-hits-per-page
                    :items="[
                        { label: '10', value: 10, default: true },
                        { label: '25', value: 25 },
                        { label: '50', value: 50 }
                    ]"
                >
                    <template v-slot="{ items, refine }">
                        <tooltip
                            :hover="false"
                            @opened="hitsPerPageTooltip = true"
                            @closed="hitsPerPageTooltip = false"
                            position="top"
                            dark
                            width="100%"
                            max-width="96vw"
                            title="Items per page"
                        >
                            <template #activator>
                                <button
                                    class="border border-ns-deep-blue px-4 py-2 lg:px-8"
                                    :class="{
                                        'rounded-b-md bg-ns-deep-purple text-ns-modern-green border-ns-deep-purple': hitsPerPageTooltip,
                                        'rounded-md hover:text-ns-deep-blue bg-transparent border-ns-deep-blue': !hitsPerPageTooltip
                                    }"
                                >
                                    Items per page
                                </button>
                            </template>

                            <ul class="flex flex-col space-y-2 pt-4">
                                <li v-for="item in items" :key="item.value">
                                    <button
                                        class="py-1 px-2 border border-white rounded w-full text-center transition-all duration-200"
                                        :class="{
                                            'font-semibold bg-white text-ns-deep-blue': item.isRefined,
                                        }"
                                        @click="setItemsPerPage(refine, item.value)"
                                    >
                                        {{ item.label }}
                                    </button>
                                </li>
                            </ul>
                        </tooltip>
                    </template>
                </ais-hits-per-page>
                <ais-pagination :padding="2">
                    <template v-slot="{ currentRefinement, nbPages, pages, isFirstPage, isLastPage, refine, createURL }">
                        <nav role="navigation" aria-label="Pagination navigation">
                            <ul
                                class="text-2xl md:text-lg mt-8 flex items-center gap-2"
                                :class="{
                                    'hidden': pages.length <= 1,
                                    'flex items-center justify-center': pages.length > 1,
                                }"
                            >
                                <li class="h-8 flex items-center">
                                    <button
                                        :disabled="isFirstPage"
                                        :href="createURL(0)"
                                        aria-label="Navigate to the first page"
                                        type="button"
                                        class="disabled:opacity-30 disabled:pointer-events-none rounded-full transition duration-300 w-8 h-8 relative group"
                                        @click.prevent="refine(0)"
                                    >
                                        <i class="fa-light fa-chevrons-left text-ns-deep-blue dark:text-white transition duration-300 group-hover:text-ns-dark-blue absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"></i>
                                    </button>
                                </li>
                                <li class="h-8 flex items-center">
                                    <button
                                        :disabled="isFirstPage"
                                        :href="createURL(currentRefinement - 1)"
                                        aria-label="Navigate to previous page"
                                        type="button"
                                        class="disabled:opacity-30 disabled:pointer-events-none rounded-full transition duration-300 w-8 h-8 relative group"
                                        @click.prevent="refine(currentRefinement - 1)"
                                    >
                                        <i class="fa-light fa-chevron-left text-ns-deep-blue dark:text-white transition duration-300 group-hover:text-ns-dark-blue absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"></i>
                                    </button>
                                </li>
                                <li class="px-4 md:px-2">
                                    <span class="font-bold">{{ currentRefinement + 1 }}</span> / {{ nbPages }}
                                </li>
                                <li class="h-8 flex items-center">
                                    <button
                                        :disabled="isLastPage"
                                        :href="createURL(currentRefinement + 1)"
                                        aria-label="Navigate to next page"
                                        type="button"
                                        class="disabled:opacity-30 disabled:pointer-events-none rounded-full transition duration-300 w-8 h-8 relative group"
                                        @click.prevent="refine(currentRefinement + 1)"
                                    >
                                        <i class="fa-light fa-chevron-right text-ns-deep-blue dark:text-white transition duration-300 group-hover:text-ns-dark-blue absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"></i>
                                    </button>
                                </li>
                                <li class="h-8 flex items-center">
                                    <button
                                        :disabled="isLastPage"
                                        :href="createURL(nbPages)"
                                        aria-label="Navigate to the last page"
                                        type="button"
                                        class="disabled:opacity-30 disabled:pointer-events-none rounded-full transition duration-300 w-8 h-8 relative group"
                                        @click.prevent="refine(nbPages)"
                                    >
                                        <i class="fa-light fa-chevrons-right text-ns-deep-blue dark:text-white transition duration-300 group-hover:text-ns-dark-blue absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"></i>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </template>
                </ais-pagination>
            </div>
        </div>
    </ais-instant-search>
</template>

<style scoped>
    .ais-RefinementList.ais-RefinementList--noRefinement.w-full.z-0 {
        display: none;
    }

    .slide-down-enter-active,
    .slide-down-leave-active {
        transform-origin: top;
        transition: transform 0.3s ease-in-out;
    }

    .slide-down-enter-to,
    .slide-down-leave-from {
        transform: scaleY(1);
    }

    .slide-down-enter-from,
    .slide-down-leave-to {
        transform: scaleY(0);
    }
</style>
