<script setup>
import { computed, onMounted, reactive } from "vue";
import Person from '@/components/people/Person.vue';
import { useOfflinePeopleStore, storeToRefs } from "@/stores/OfflinePeople";

const store = useOfflinePeopleStore();
const {
    loadPeople,
    loadFilterValues,
    setFilter,
    clearFilter,
} = store;
const {
    people,
    search,
    filters,
    nbHits,
    selectedFilters,
    filteredPeople
} = storeToRefs(store);

onMounted(() => {
    loadPeople().then(() => {
        loadFilterValues('people_function');
        loadFilterValues('people_sector');
        loadFilterValues('office');
        loadFilterValues('people_leadership_group');
    });
});
</script>

<template>
    <div class="flex flex-col space-y-6">
        <div class="flex flex-col space-y-2">
            <div>
                <input
                    type="text"
                    id="search"
                    v-model="search"
                    class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                    placeholder="Search by name"
                />
            </div>

            <div
                class="rounded-lg text-ns-deep-blue relative z-0 border border-ns-deep-blue dark:border-white dark:text-white"
                v-for="filter in filters"
            >
                <button
                    class="w-full text-left flex justify-between px-4 py-2 z-0"
                    @click="filter.open = !filter.open"
                >
                    <span class="text-base my-0">
                        {{ filter.label }}
                    </span>
                    <i
                        class="fa-sharp fa-regular fa-chevron-down text-xl transition duration-300"
                    ></i>
                </button>

                <transition name="slide-down">
                    <div
                        class="flex flex-col space-y-3 p-4 pt-2 z-50 w-full"
                        v-if="filter.open"
                    >
                        <a
                            v-for="value in filter.values"
                            class="flex items-center space-x-2"
                            :class="{
                                'font-semibold': filter.active.includes(value),
                            }"
                            @click.prevent="setFilter(filter.key, value)"
                        >
                            <input
                                :checked="filter.active.includes(value)"
                                type="checkbox"
                                class="h-6 w-6 pointer-events-none"
                                aria-hidden="true"
                                tabindex="-1"
                            >
                            <div class="flex-grow flex items-center justify-between w-full cursor-pointer">
                                {{ value }}
                            </div>
                        </a>
                    </div>
                </transition>
            </div>

<!--            Badges for selected filters -->
            <div
                class="flex flex-wrap gap-2 py-4"
                v-if="selectedFilters.length"
            >
                <p
                    v-for="filter in selectedFilters"
                    class="my-0 inline-flex gap-x-2 items-center py-2 p-5 pr-12 bg-ns-dark-blue text-white rounded-lg transition duration-300 relative text-sm"
                >
                    <span>{{ filter }}</span>
                    <i
                        class="fa-sharp fa-solid fa-times absolute right-5 top-1/2 -translate-y-1/2 cursor-pointer"
                        @click="clearFilter(filter)"
                    ></i>
                </p>
            </div>

            <span><span>Your search returned </span><span class="font-bold">{{ nbHits }} {{ nbHits === 1 ? 'result' : 'results' }}</span></span>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-6">
            <person
                v-for="item in filteredPeople"
                :key="item.objectID"
                :person="item"
                :show-checkbox="false"
            />
        </div>
    </div>
</template>

<style scoped>
.ais-RefinementList.ais-RefinementList--noRefinement.w-full.z-0 {
    display: none;
}

.slide-down-enter-active,
.slide-down-leave-active {
    transform-origin: top;
    transition: transform 0.3s ease-in-out;
}

.slide-down-enter-to,
.slide-down-leave-from {
    transform: scaleY(1);
}

.slide-down-enter-from,
.slide-down-leave-to {
    transform: scaleY(0);
}
</style>
