<script setup>
import {ref, onMounted, computed, reactive, watch} from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore, storeToRefs } from "@/stores/Auth";

const authStore = useAuthStore();
const { createMainSiteAccount } = authStore;
const { user, userHasMainSiteAccount } = storeToRefs(authStore);

const router = useRouter();

const navigateTo = (item) => {
    if (item.disabled) {
        alert('Only available when you have a connection');
        return;
    }
    if (item.href) {
        if (item.opensInNewTab) {
            window.open(item.href, '_blank');
        } else {
            window.location.href = item.href;
        }
    }
    if (item.path) {
        router.push(item.path);
    }
};

const goToLogin = () => {
    window.location.href = '/login';
};
const splash = ref();

const showSplash = ref(!localStorage.getItem('splashShown'));

const loggedIn = computed(() => !!user.value);

const isOnline = ref(navigator.onLine);

const enterApp = () => {
    splash.value.classList.add('splash-screen-fade-out');
    localStorage.setItem('splashShown', 'true');

    setTimeout(() => {
        showSplash.value = false;
    }, 1000);
};

onMounted(() => {
    setLandingPageItems();
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
});

const updateOnlineStatus = () => {
    isOnline.value = navigator.onLine;
    setLandingPageItems();
};

const logout = () => {
    window.location.href = '/logout';
}



const items = ref([]);

const setLandingPageItems = () => {
    items.value = [
        {
            title: 'Vessel Search',
            icon: 'fa-ns-ship',
            iconClasses: 'text-5xl my-4',
            background: '/assets/backgrounds/vessels_bg.png',
            path: '/vessel-search',
            disabled: false,
            show: true,
        },
        {
            title: 'Correspondents',
            icon: 'fa-ns-group',
            iconClasses: 'text-5xl my-4',
            background: '/assets/backgrounds/correspondents_bg.png',
            path: '/correspondents-search',
            disabled: false,
            show: true,
        },
        {
            title: 'People',
            icon: 'fa-ns-book',
            iconClasses: 'text-5xl my-4',
            background: '/assets/backgrounds/people_bg.png',
            path: '/people/search',
            disabled: false,
            show: true,
        },
        {
            title: 'Contacts Lists',
            icon: 'fa-regular fa-users-rectangle',
            iconClasses: 'text-4xl my-5',
            background: '/assets/backgrounds/sign_in.png',
            path: '/people',
            disabled: false,
            show: loggedIn.value,
        },
        {
            title: 'Rules',
            icon: 'fa-ns-gavel',
            iconClasses: 'text-4xl my-5',
            background: '/assets/backgrounds/rules_bg.png',
            href: 'https://north-standard.com/insights-and-resources/resources/our-rules',
            opensInNewTab: true,
            disabled: !isOnline.value, // Disable if offline
            show: true,
        },
        {
            title: 'Emergency 24/7',
            icon: 'fa-ns-emergency',
            iconClasses: 'text-4xl my-5',
            background: '/assets/backgrounds/emergency_bg.png',
            path: '/emergency-contact',
            disabled: false,
            show: true,
        },
        {
            title: 'Sign in',
            icon: 'fa-kit fa-ns-user',
            iconClasses: 'text-4xl my-5',
            background: '/assets/backgrounds/sign_in.png',
            href: '/login',
            disabled: false,
            show: !loggedIn.value,
        },
    ];
}

watch(loggedIn, () => {
    setLandingPageItems();
});
</script>

<template>
    <div v-if="showSplash" class="splash-screen dark" ref="splash">
        <div class="splash-content">
            <img src="/assets/logos/north_standard_white.png" alt="NorthStandard Logo" class="logo" />
            <div class="grid grid-cols-1 gap-0 w-full max-w-md px-4">
                <p class="splash-text text-white text-center mt-16 text-md font-semibold">Navigate with confidence. Welcome to the NorthStandard App.</p>
                <p class="splash-text text-white text-center">Access critical maritime information – 24/7 – with the NorthStandard app. Find favourite contacts, perform vessel searches, view rules, and access emergency numbers – even without an internet connection.</p>
                <p class="splash-text text-white text-center">Simply log in to your NorthStandard account and get the information that matters most – in one convenient location.</p>
                <div class="splash-button flex justify-center mt-8">
                    <ns-button label="Enter App" @click="enterApp" />
                </div>
            </div>
        </div>
    </div>

    <div v-show="!showSplash" class="text-white flex flex-col items-center fade-in space-y-4 pb-8">
        <div class="w-full max-w-md text-center pt-4">
            <p class="text-sm my-0">Stay informed and in control with instant access to critical resources – anytime, anywhere.</p>
        </div>
        <div class="grid grid-cols-2 gap-4 w-full px-4">
            <div
                v-for="item in items.filter(item => item.show)"
                :key="item.title"
                class="tile glass relative flex flex-col bg-cover bg-center fade-in-tile ns-shadow"
                :class="{ '_tile__disabled': item.disabled }"
                @click="navigateTo(item)"
            >
                <div class="overlay absolute inset-0"></div>
                <i v-if="item.icon === 'fa-ns-emergency'"
                   class="fa-kit fa-ns-emergency relative my-5 text-4xl text-ns-dark-orange">
                </i>
                <i v-else
                   :class="`fa-kit ${item.icon} ${item.iconClasses} relative text-ns-modern-green my-4`"
                   :style="{ color: item.disabled ? 'grey' : '' }">
                </i>
                <div class="bg-ns-modern-green bottom-0 text-ns-deep-blue w-full text-sm md:text-md text-left font-semibold py-2 px-4">
                    {{ item.title }}
                </div>
            </div>
        </div>

        <div class="flex w-full py-8 px-4" v-if="loggedIn">
            <ns-button label="Log out" outline colour="red" class="w-full" @click="logout" />
        </div>
    </div>
</template>

<style scoped lang="scss">
    .splash-screen {
        @apply fixed top-0 left-0 w-full h-full z-50 bg-ns-deep-purple flex justify-center items-center;
    }

    .splash-screen-fade-out {
        animation: fadeOut 1s ease-in-out 0s forwards;
    }

    .splash-content {
        @apply flex flex-col items-center;
    }

    .logo {
        @apply w-[200px];

        animation: fadeInLogo 1s ease-in-out;
    }

    .splash-text {
        animation: fadeInText 1s ease-in-out 1s forwards;

        @apply opacity-0;
    }

    .splash-text-fade-out {
        animation: fadeOutText 1s ease-in-out 3s forwards;
    }

    .splash-button {
        animation: fadeInText 1s ease-in-out 2s forwards;
        @apply opacity-0;
    }

    @keyframes fadeInLogo {
        from {
            @apply opacity-0;
        }
        to {
            @apply opacity-100;
        }
    }

    @keyframes fadeInText {
        from {
            @apply opacity-0;
        }
        to {
            @apply opacity-100;
        }
    }

    @keyframes fadeOutText {
        from {
            @apply opacity-100;
        }
        to {
            @apply opacity-0
        }
    }

    @keyframes fadeOut {
        from {
            @apply opacity-100;
        }
        to {
            @apply opacity-0
        }
    }

    .fade-in {
        animation: fadeInContent 1s ease-in-out;
    }

    @keyframes fadeInContent {
        from {
            @apply opacity-0;
        }
        to {
            @apply opacity-100;
        }
    }

    .fade-in-tile {
        animation: fadeInTile 1.5s ease-in-out;
    }

    @keyframes fadeInTile {
        from {
            @apply opacity-0 translate-y-[20px];
        }
        to {
            @apply opacity-100 translate-y-0;
        }
    }

    .tile {
        @apply rounded-xl overflow-hidden cursor-pointer text-center;

        &._tile__disabled {
            @apply grayscale;
        }
    }
</style>
