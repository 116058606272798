import { defineStore } from 'pinia'
import {computed, ref} from 'vue';
import axios from 'axios';
import { storeResults } from '@/idb-utils';

const useCorrespondentList = defineStore('CorrespondentList', () => {
    const correspondentList = ref([]);

    const getCsrf = () => {
        return axios.get('/sanctum/csrf-cookie');
    }
    const getCorrespondentList = async () => {
        try {
            await getCsrf();
            const response = await axios.get('/api/correspondent-list');
            if (response.data) {
                correspondentList.value = response.data;
                console.log('correspondentList populated:', correspondentList.value);
                //await savePortsToIndexedDB();
            } else {
                console.error('Failed to get correspondent list data');
            }
        } catch (error) {
            console.error('Error in getCorrespondentList:', error);
        }
    };
    const savedPorts = computed(() => {
        const ports = {};
        correspondentList.value.forEach(item => {
            const country = item.port_country;
            if (!ports[country]) {
                ports[country] = [];
            }
            ports[country].push({
                name: item.port_name
            });
        });
        console.log('Transformed savedPorts:', ports);
        return ports;
    });

    const savePortsToIndexedDB = async () => {
        const ports = savedPorts.value;
        const portArray = [];
        for (const country in ports) {
            portArray.push(...ports[country]);
        }
        console.log('Ports to be saved to IndexedDB:', portArray); // Add this line
        await storeResults('saved-ports', portArray);
    };

    return {
        correspondentList,
        getCorrespondentList,
        savedPorts
    };
});

export { useCorrespondentList };
